<template>
  <v-container class="container">
    <div v-if="step===1" class="wrap">
      <p class="my-5 text-center text-h3">ショッピングカートの確認</p>
      <p class="my-7" v-if="regiItems.length<1">カートにチケットが入っていません。</p>
      <v-card class="mx-auto mb-5 " max-width="500px"  v-for="regiItem in shapedRegiItems" :key="regiItem.concertId">
        <v-row>
          <v-col>
            <div class="pa-2">
            <!-- <div class="concert-title-wrapper"> -->
              <!-- <p class="concert-title  mb-0 pr-3 pt-3"> -->
                <!-- <v-icon class="title-icon pb-3 px-2">mdi-music-note</v-icon> -->
                <p class="px-3 mb-0 text-h3">{{regiItem.concertName}}</p>
              <!-- </p> -->
            <!-- </div> -->
            </div>
          </v-col>
        </v-row>
        <v-divider class="mb-2"></v-divider>
        <div v-for="ticket in regiItem.tickets" :key="ticket.price" class="pa-2">
            <v-row>
              <v-col>
                  <p class="pl-3 mb-0">{{ticket.ticketName}}</p>
              </v-col>
              <v-col cols="3" class="delete text-center">
                <v-btn @click="handleDialog(regiItem.concertId,ticket.price)" depressed plain small>
                <v-icon size="22" class="pb-2">mdi-trash-can-outline</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="price">
                  <p class="pl-3 value">{{ticket.ticketValue | priceLocaleString}}</p>
                  <span class="pl-1 unit">円</span>
              </v-col>
              <v-col class="quantity-control" cols="7">
                  <div class="quantity-plusminus">
                    <div class="plusminus-icon">
                      <v-icon size="20" @click="minus(regiItem.concertId,ticket.price,ticket.quantity)">mdi-minus-circle-outline</v-icon>
                    </div>
                    <span class="quantity text-center">
                      {{ticket.quantity}}
                      <span class="unit">枚</span>
                    </span>
                    <div class="plusminus-icon">
                      <v-icon class="" size="20" @click="plus(regiItem.concertId,ticket.price,ticket.quantity)">mdi-plus-circle-outline</v-icon>    
                    </div>
                  </div>
              </v-col>
            </v-row>
            <v-divider class="mt-3" v-if="regiItem.tickets.length>1"></v-divider>
        </div>
      </v-card>

      <v-card class="mx-auto mb-7 pa-2" max-width="500px" tile>
        <div>
          <v-row>
            <v-col class="">
                <p class="pl-3 mb-0">合計</p>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="price text-right pa-0">
              <v-card-title class="pa-0 pl-3" align="right" justify="right">
                <p class="pl-3 value">{{total | priceLocaleString}}</p>
                <span class="pl-1 unit">円(税込)</span>
              </v-card-title>  
            </v-col>
          </v-row>
        </div>
      </v-card> 
      <v-row justify="center" class="py-2">
        <v-col cols="12" class="text-center">
          <v-btn v-if="total>0" @click="step=2"  color="primary">
            <v-icon
              color="white"
              class="pr-3"
              size="20"
            >
              mdi-chevron-right-circle
            </v-icon>
            お客様情報の入力・確認へ進む</v-btn>
        </v-col>
      </v-row>
      <v-row justify="center" class="py-2">
        <v-col cols="12" class="text-center">
          <v-btn class="mx-3" @click="$router.push('/')" color="primary" outlined>コンサート一覧に戻る</v-btn>
        </v-col>
      </v-row>
    </div>
    <v-dialog
      v-model="dialog1"
      width="500"
    >

      <v-card>
        <v-card-title class="grey lighten-2 py-1">
         確認
        </v-card-title>
        <p class="pl-3 pt-3 text-body1">このチケットを削除してよろしいですか？</p>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="deleteTicket()"
          >
            削除する
          </v-btn>
          <v-btn
            color="primary"
            outlined
            @click="dialog1=false"
          >
            削除しない
          </v-btn>          
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div v-if="step===2">
      <p class="text-center text-h3">お客様情報の入力・確認</p>
      <v-card v-if="regiItems.length>0" class="mx-auto mb-5 pa-3" max-width="500px" tile>      
        <v-form
          ref="form"
          lazy-validation
        >
        <v-subheader class="pl-0">メール</v-subheader>
        <v-row>
          <v-col cols="12" class="pl-3">
            {{currentUser.email}}
          </v-col>
        </v-row>      
          <v-subheader class="pl-0">お名前</v-subheader>
          <v-row>
            <v-col cols="6" class="pb-0 pr-1">
              <v-text-field
                label="姓"
                v-model="targetUser.myouji"
                class=""
                dense
                single-line
                :rules=[Rules.required]
                validate-on-blur
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="pb-0 pl-1">
              <v-text-field
                v-model="targetUser.namae"
                label="名"
                class=""
                dense
                single-line
                :rules=[Rules.required]
                validate-on-blur
              ></v-text-field>
            </v-col>
          </v-row>
          <v-subheader class="pl-0">ふりがな</v-subheader>
          <v-row>
            <v-col cols="6" class="pr-1">
              <v-text-field
                v-model="targetUser.myouji_kana"
                class=""
                dense
                single-line
                label="せい"
                :rules=[Rules.required,Rules.kana]
                validate-on-blur
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="pl-1">
              <v-text-field
                v-model="targetUser.namae_kana"
                class=""
                dense
                single-line
                label="めい"
                :rules=[Rules.required,Rules.kana]
                validate-on-blur
              ></v-text-field>
            </v-col>
          </v-row>
          <v-subheader class="pl-0">連絡先電話番号</v-subheader>
          <v-row>
            <v-col cols="12" class="pl-3">
              <v-text-field
                v-model="targetUser.tel"
                class=""
                dense
                hint="ハイフン無し。数字のみ。"
                single-line
                :rules=[Rules.required,Rules.number]
                validate-on-blur
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card>
      <v-row justify="center" class="py-2">
        <v-col cols="12" class="text-center">
          <v-btn v-if="total>0" @click="checkout()"  color="primary" :disabled="loading"  :loading="loading">
            <v-icon
              color="white"
              class="pr-3"
              size="20"
            >
              mdi-chevron-right-circle
            </v-icon>            
            支払いへ進む
          </v-btn>
        </v-col>
      </v-row>
      <v-row justify="center" class="py-2">
        <v-col cols="12" class="text-center">
          <v-btn class="mx-3" @click="step=1" color="secondary" outlined>戻る</v-btn>
        </v-col>
      </v-row>    
    </div>


</v-container>
</template>

<script>
import axios from 'axios'
import '../axios_setting.js'
import {auth} from '../firebase'
import { mapGetters } from 'vuex'


export default {
  name: 'RC_RegiCheck',
  components: {
  },

  data: () => ({
    Rules: {
          required: v => !!v || '入力必須です',
          number: v => /^[0-9０-９]+$/.test(v) || v==="" || '数字のみ入力してください',
          kana: v => /^[ぁ-んー]+$/.test(v) || 'ひらがなで入力してください',
          mail: v => /^[a-zA-Z0-9_+-]+(.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/.test(v)|| '入力内容を確認してください',
          maxlength: v => (v && v.length <= 11) || '入力内容を確認してください',
          minlength: v => (v && v.length >= 10) || '入力内容を確認してください'
        },
    loading:false, 
    step:1,
    not_online_total_quantity:0,
    dialog1:false,
    concertId:null,
    priceId:null,
  }),
  mounted(){
    // Object.assign(this.targetUser,this.user)
  },
  created(){
  },
  computed:{
    ...mapGetters([
      'concerts',
      'user',
      'currentUser',
      'regiItems',
    ]),
    targetUser(){
        return this.user
    },
    shapedRegiItems() {
      //表示用に、コンサート名称やチケット名称を追加
      let result = []
      //deepcopyしないとstate.regiItemsと連動してしまう
      let original = JSON.parse(JSON.stringify(this.regiItems))
      if(this.concerts.length>0){
        //削除すべき、数量0のチケットのインデックスを入れる
        let deleteIndex = []
        for(let i=0;i<original.length;i++){
          //コンサート名称まわり
          let concertId = original[i].concertId
          let the_concert = this.concerts.find(ele => ele.id === concertId)
          original[i].concertName = the_concert.name
          //コンサートチケットまわり
          for(let j=0;j<original[i].tickets.length;j++){
            if(original[i].tickets[j].quantity>0){
            let ticketId = original[i].tickets[j].price
            let ticketName = the_concert.tickets.find(ele => ele.id === ticketId).name
            let ticketValue = the_concert.tickets.find(ele => ele.id === ticketId).price
            original[i].tickets[j].ticketName = ticketName
            original[i].tickets[j].ticketValue = ticketValue
            } else{
              deleteIndex.push(j)
            }
          }
          for(let k=0;k<deleteIndex.length;k++){
          delete original[i].tickets[deleteIndex[k]]
          }
          //空の配列要素を除去
          original[i].tickets = original[i].tickets.filter(v => v)
          result.push(original[i])
        }
      } else {
        result.push({concertId:null,artist_uid:null,tickets:[{price:null,quantity:null,ticketName:null,ticketValue:0}]})
      }
      return result
    },
    setUser: {
        get(value) {
          return value
        },
        set() {
            Object.assign(this.targetUser,this.user)
        }
    },
    total(){
      let result = 0
      for(let i=0;i<this.shapedRegiItems.length;i++){
        for(let j=0;j<this.shapedRegiItems[i].tickets.length;j++){
          let subtotal = this.shapedRegiItems[i].tickets[j].quantity * this.shapedRegiItems[i].tickets[j].ticketValue
          result += subtotal
        }
      }
      return result
    }    
  },
  methods:{
    regiDelete(){
      this.$store.commit('delete_all_regiItems')
    },
    checkOnline(concerts,priceId,concertId){
      let the_concert = concerts.find(item => item.id === concertId)
      let the_ticket =the_concert.tickets.find(item => item.id === priceId)
      return the_ticket.online
    },  
    checkout() {
      if (this.$refs.form.validate()) {
        auth.currentUser.getIdToken(true).then(
        (idToken) => {
          //stripeに渡す用のチケット配列
          let a = []
          //0枚のチケット種別を削除
          for (let i=0;i<this.regiItems[0].tickets.length;i++){
            if(this.regiItems[0].tickets[i].quantity>0){
              a.push(this.regiItems[0].tickets[i])
            }
          }
          //オンライン以外のチケット枚数をカウント
          for(let i=0;i<a.length;i++){
            if(!this.checkOnline(this.concerts,a[i].price,this.regiItems[0].concertId)){
              this.not_online_total_quantity += a[i].quantity
            }
          }
          //在庫より少なければOK
          if(this.concerts.find(item => item.id === this.regiItems[0].concertId).limit_quantity>=this.not_online_total_quantity){
            this.loading=true
            this.$store.commit("set_loading",true)
            this.$store.dispatch('set_alert',{"type":"success",timeout:"30000","message":"決済画面へ移動中です。画面が切り替わるまでそのままお待ちください。<br>10秒程度かかる場合があります。"})
            axios.post(
              process.env.VUE_APP_API_ROOT+"/create-checkout-session",
              { 
                concertId:this.regiItems[0].concertId, //ハードコーディング
                artist_uid:this.regiItems[0].artist_uid,
                idToken:idToken,
                items:a,
                not_online_total_quantity:this.not_online_total_quantity,
                email:this.currentUser.email,
                myouji:this.user.myouji,
                namae:this.user.namae,
                myouji_kana:this.user.myouji_kana,
                namae_kana:this.user.namae_kana,
                tel:this.user.tel,
              },
              {withCredentials: true})
          .then(            
              res => {
              const body = res.data
              window.location.replace(body.url)
              this.$store.commit('delete_all_regiItems')
              this.$store.commit("set_loading",true)
              })
          .catch(e => {
              console.error(e);
              });
          } else {
            window.scrollTo({
              top: 0,
            })          
            this.$store.dispatch('set_alert', {
              "type": "error",
              "message":`チケットの残り席数は${this.concerts.find(item => item.id === this.regiItems[0].concertId).limit_quantity}席です。購入枚数を変更ください。<br>(オンラインチケットは枚数に含みません)`,
             "timeout":5000
             })
             this.step=1
             //初期化
             this.not_online_total_quantity = 0
          }               
        })
      //バリデーションエラーの場合
      } else {
        this.$store.dispatch('set_alert', {
          "type": "error",
          "message":'入力内容をご確認ください。',
          "timeout":3000
        })
      }      
    },
    minus(concertId,priceId,quantity){
      if(quantity>1){
        this.$store.commit('set_regiItems',
            {
              "concertId":concertId,
              "tickets":[{"price":priceId,"quantity":-1}],
            }
        )
      } else {
        this.handleDialog(concertId,priceId)
      }
    },
    plus(concertId,priceId,quantity){
      if(quantity<10){
        this.$store.commit('set_regiItems',
            {
              "concertId":concertId,
              "tickets":[{"price":priceId,"quantity":1}],
            }
        )  
      } else {
        window.alert("一度に購入できるのは10枚までです。")
      }  
    },
    deleteTicket(){
      // if(window.confirm("カートからこのチケットを削除してよろしいですか？")){
        this.$store.commit('delete_regiItems',{concertId:this.concertId,priceId:this.priceId})
        this.dialog1 = false
      // }
    },
    handleDialog(concertId,priceId){
      this.dialog1 = true
      this.concertId = concertId
      this.priceId = priceId
    }
  },
};
</script>
<style lang="scss" scoped>


.wrap{
  max-width: 500px;
  margin: 0 auto;
}
.stepper{
  margin:0 auto;
}
.title-icon{
  display: inline-block;
}
.container{
  background-color: #F5F5F5;
}
.concert-title{
  display: inline-block;

}

.delete{
  width: 100px;
}
.price {
  display: inline;
}
.value{
  display: inline;
  font-size: 1.3rem;
}

.unit{
  display: inline;
  font-size: 0.8rem;
}

.quantity-control{
  display: flex;
  justify-content: center;
  align-items: center;
}

.quantity-plusminus{
  display: flex;
  .plusminus-icon{
    width: 50px;
    text-align:center;
  }
}

.quantity{
  font-size:1.3rem;
  display: inline;
  width: 50px;
}

.btn-area{
  display: inline;
  vertical-align: center;
  max-width: 500px;
  line-height: 100px;
  text-align: center;
}


</style>
