import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/auth'
import "firebase/compat/storage";
import { getAnalytics } from "firebase/analytics";


const firebase_app = firebase.initializeApp({
  apiKey: "AIzaSyCeRf1Ln7ek-A_spzNoT6SVQZx1C8P3_jY",
  authDomain: "ticket-auth.ricco-piano.com",
  projectId: "meetclassic-5737b",
  storageBucket: "meetclassic-5737b.appspot.com",
  messagingSenderId: "713401423733",
  appId: "1:713401423733:web:06eb29db881fb59346653c",
})

export const analytics = getAnalytics(firebase_app);

//エミュレーター用/////
// const db_obj = firebase.firestore();
// db_obj.useEmulator("localhost", 8082);

// const auth_obj = firebase.auth();
// auth_obj.useEmulator("http://localhost:9099");
// auth_obj.useDeviceLanguage();

// const provider_obj = new firebase.auth.GoogleAuthProvider()
// provider_obj.setCustomParameters({
//     prompt: 'select_account'
//  });
// provider_obj.addScope('email')

// export const db = db_obj
// export const TIMESTAMP = firebase.firestore.FieldValue.serverTimestamp()
// export const NOW = firebase.firestore.Timestamp.now()
// export const auth = auth_obj
// export const provider = provider_obj
///////////////////

// ////本番//////////
export const db = firebase.firestore()
//firestore()ではなくfirestoreであることに注意
//https://stackoverflow.com/questions/50361962/cloud-functions-cloud-firestore-error-cant-get-servertimestamp
export const TIMESTAMP = firebase.firestore.FieldValue.serverTimestamp()
export const NOW = firebase.firestore.Timestamp.now()
export const auth = firebase.auth()
export const provider = new firebase.auth.GoogleAuthProvider()
provider.setCustomParameters({
    prompt: 'select_account'
 });

export const storage = firebase.storage()
// ////本番//////////
