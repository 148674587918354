<template>
  <v-footer
    color="accent darken-1"
    padless
  >
    <v-row
      justify="center"
      no-gutters
    >
      <v-btn
        v-for="link in links"
        :key="link.name"
        text
        color="white"
        small
        rounded
        class="my-1"
        @click="$router.push(link.path).catch(()=>{})"
      >
        {{ link.name }}
      </v-btn>
      <v-col
        class="primary lighten-2 py-2 text-center white--text text-caption"
        cols="12"
      >
        {{ new Date().getFullYear() }} - riccoチケット
      </v-col>
    </v-row>
  </v-footer>
</template>
<script>
import {auth} from '../firebase'


export default {
    name: 'RiccoFooter',
    components: {
    },
    data: function () {
        return {
            drawer:false,
            group:null,
            links:[{name:"特定商取引法に基づく表記",path:"/tokusho"},{name:"プライバシーポリシー",path:"/privacy-policy"}]
        }
    },
    computed: {
        is_signed_in: function() {
            return this.$store.getters.currentUser
        },
        activeList: function(){
            return this.$route.path
        },
        regiItems(){
          return this.$store.getters.regiItems
        },
        currentUser(){
          return this.$store.getters.currentUser
        }
    },
    created () {
    },

    mounted() {
    },
    beforeDestroy () {
    },
    methods: {
        signOut () {
            if (window.confirm('ログアウトしてよろしいですか。')) {
                auth.signOut()
                .then(() => {this.$router.push('/').catch(()=>{})
                })
            }
        },
        toHome () {
        this.drawer = !this.drawer
        this.group = 0
        this.$router.push('/').catch(()=>{})
        },
    },
}
</script>

<style scoped>
.btn-area {
    margin: 20px 0 20px 0;
    text-align: center;
}

</style>