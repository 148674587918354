<template>
  <v-container>
    <div class="text-body1">エラーが発生しました。</div>

        <div class="my-6"><v-btn @click="$router.push('/')" color="primary" dark>ホームへ</v-btn></div>

    </v-container>
</template>

<script>


export default {
  name: 'RC_Error',

  components: {
  },

  data: () => ({
    email:"",
  }),
  mounted() {
  },
  methods:{
  }
};
</script>
<style lang="scss">
/* エミュレーターの注意書きを削除 */
.firebase-emulator-warning { display: none; }
</style>
