import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
// import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
          light: {
            primary:"#635152",
            secondary: "#a89495",
            accent: "#9fcad7",
            accent2:"#f5cfcd",
            subtle:"#EEEEEE",
            error: "#e1706a",
            warning: "#FF9800",
            info: "#5b8c5a",
            success: "#00c49a",
            rcwhite:"#fcfcfc",
            rcblack:"#0f0c0c",
          },
          dark: {
            primary: "#EE6c4d",
            secondary: "#3d5a80",
            accent: "#9fcad7",
            accent2:"#f5cfcd",            
          },
        },
      },    
});
