<template>
  <v-app>
    <ricco-top-navi></ricco-top-navi>
    <v-main class="main">
        <app-alert></app-alert>
        <app-loading v-show="loading"></app-loading>
        <router-view v-show="!loading"></router-view>
    </v-main>
    <ricco-footer></ricco-footer>
  </v-app>
</template>

<script>
import RiccoTopNavi from './components/RiccoTopNavi.vue'
import RiccoFooter from './components/RiccoFooter.vue'
import AppLoading from "./components/AppLoading.vue"
import AppAlert from './components/AppAlert.vue'



export default {
  name: 'App',
  components: {
    RiccoTopNavi,
    RiccoFooter,
    AppLoading,
    AppAlert,
  },
  async created(){
    this.$store.dispatch('initialize')
    this.$store.dispatch('sync_firestore')
  },
  data: () => ({
  }),
  computed:{
    loading(){
      return this.$store.getters.loading
    },    
  },
  methods:{
  }
};
</script>
<style scoped lang="scss">
/* エミュレーターの注意書きを削除 */
.firebase-emulator-warning { display: none; }

.main{
  background-color: #F5F5F5;
}
</style>
