<template>
  <v-container>
    <p class="my-5 text-center text-h3">お問合せ</p>
      <v-card class="mx-auto mb-5 pa-3" max-width="500px" tile>      
        <v-form
          ref="form"
          lazy-validation
        >
          <v-subheader class="pl-0">お名前</v-subheader>
          <v-row>
            <v-col cols="6" class="pb-0 pr-1">
              <v-text-field
                label="姓"
                v-model="myouji"
                class=""
                dense
                outlined
                :rules=[Rules.required]
                validate-on-blur
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="pb-0 pl-1">
              <v-text-field
                v-model="namae"
                label="名"
                class=""
                dense
                outlined
                :rules=[Rules.required]
                validate-on-blur
              ></v-text-field>
            </v-col>
          </v-row>
          <v-subheader class="pl-0">メール</v-subheader>
            <v-row>
                <v-col cols="12" class="pl-3">
                  <v-text-field
                    v-model="sync_email"
                    class=""
                    dense
                    outlined
                    :rules=[Rules.required,Rules.mail]
                    validate-on-blur
                  ></v-text-field>
                </v-col>
              </v-row>
          <v-subheader class="pl-0">お問合せ内容</v-subheader>
            <v-row>
              <v-col cols="12" class="pl-2">
                  <v-textarea
                    v-model="contact_content"
                    :readonly="readonly"
                    outlined
                    height="250"
                    :rules=[Rules.required]
                  ></v-textarea>
              </v-col>
            </v-row>  
            <v-row>
              <v-col>
                <VueRecaptcha
                  :sitekey="recaptchaKey"
                  ref="recaptcha"
                  @verify="onVerify"
                  @expired="onExpired"            
                ></VueRecaptcha>
              </v-col>      
            </v-row>
            <v-row justify="center" class="py-2">
              <v-col cols="12" class="text-center">
                <v-btn @click="submit()"  color="primary" :loading="loading" :disabled="disabled">送信する</v-btn>
              </v-col>
            </v-row>
        </v-form>
      </v-card>

      <v-row class="my-7" no-gutters>
        <v-col class="text-center">
        <v-btn @click="$router.push('/')" color="primary" outlined>ホームへ</v-btn>
        </v-col>
      </v-row>  

    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import {VueRecaptcha} from 'vue-recaptcha'
import axios from 'axios'
import '../axios_setting.js'

export default {
  name: 'RC_Contact',
  components: {
    VueRecaptcha
  },
  computed:{
    ...mapGetters([
      'currentUser',
    ]),
    sync_email:{
      get(){
        if(this.currentUser){
          return this.currentUser.email
        } else {
          return this.email
        }
      },
      set(value){
        this.email = value
      }
    }
  },
  data: () => ({
    Rules: {
          required: v => !!v || '入力必須です',
          number: v => /^[0-9０-９]+$/.test(v) || v==="" || '数字のみ入力してください',
          kana: v => /^[ぁ-んー]+$/.test(v) || 'ひらがなで入力してください',
          mail: v => /^[a-zA-Z0-9_+-]+(.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/.test(v)|| '入力内容を確認してください',
          maxlength: v => (v && v.length <= 11) || '入力内容を確認してください',
          minlength: v => (v && v.length >= 10) || '入力内容を確認してください'
        },
    readonly:false,
    loading:false,
    disabled:true,
    myouji:"",
    namae:"",
    email:"",
    contact_content:"", 
    recaptchaKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY_V2,
  }),
  mounted() {
  },
  created(){
  },
  methods:{
    submit(){
      if (this.$refs.form.validate()) {
        this.loading = true
        let email_check
        let uid
        if (this.currentUser){
          email_check = this.currentUser.email
          uid = this.currentUser.uid
        } else {
          email_check = this.email
          uid = "not_login"
        }
        axios.post(
              process.env.VUE_APP_API_ROOT+"/contact",
              {
                myouji:this.myouji,
                namae:this.namae,
                email:email_check,
                uid:uid,
                contact_content:this.contact_content
              },
              {withCredentials: true})
          .then(()=>{
            this.loading = false
            this.resetRecaptcha()
            this.$store.dispatch('set_alert',
            {
              "type":"success",
              timeout:"3000",
              "message":"お問合せありがとうございました。<br>確認のメールをお送りしています。"
            })  
          })
          .catch(e => {
              console.error(e);
              });
      } else {
        window.scrollTo({
              top: 0,
            })          
            this.$store.dispatch('set_alert', {
          "type": "error",
          "message":'入力内容をご確認ください。',
          "timeout":3000
        })
      }
    },
    onVerify(response) {
      if(response !== '') {
        this.disabled = false
      } else {
        return false
      }
    },
    onExpired() {
      this.resetRecaptcha()
    },
    resetRecaptcha() {
      this.$refs.recaptcha.reset()
      this.disabled = true
    },    
  }
};
</script>
<style lang="scss">
/* エミュレーターの注意書きを削除 */
.firebase-emulator-warning { display: none; }
</style>
