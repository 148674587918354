import Vue from 'vue'
import Router from 'vue-router'

import RC_Auth from '../views/RC_Auth.vue'
import RC_Home from '../views/RC_Home.vue'
import RC_AuthCheck from '../views/RC_AuthCheck.vue'
import RC_Error from '../views/RC_Error.vue'
import RC_Cancel from '../views/RC_Cancel.vue'

import RC_RegiCheck from '../views/RC_RegiCheck.vue'
import RC_MyTicket from '../views/RC_MyTicket.vue'

import RC_Success from '../views/RC_Success.vue'
import RC_Contact from '../views/RC_Contact.vue'


import RC_CustomerProfile from '../views/RC_CustomerProfile.vue'

import RC_DetailConcert from '../views/RC_DetailConcert.vue'
import RC_A_MyConcertDetail from '../views/RC_A_MyConcertDetail'
import RC_A_MyConcert from '../views/RC_A_MyConcert'
import RC_TicketCheck from '../views/RC_TicketCheck'


import RC_A_Home from '../artist/views/RC_A_Home'
import RC_A_Test from '../artist/views/RC_A_Test'


import RC_Tokusho from '../views/RC_Tokusho.vue'
import RC_PrivacyPolicy from '../views/RC_PrivacyPolicy.vue'


import {auth} from '../firebase'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: '/',
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: RC_Home,
      meta:{
        requiresAuth: false
      }
    },
    {
      path: '/auth',
      name: 'auth',
      component: RC_Auth,
    },
    {
      path: '/cancel',
      name: 'cancel',
      component:RC_Cancel,
      meta:{
        requiresAuth: false
      }
    },
    {
      path: '/success',
      name:'success',
      component:RC_Success,
      meta:{
        requiresAuth: true
      }
    },
    {
      path: '/auth-check',
      name:'auth-check',
      component:RC_AuthCheck,
    },
    {
      path: '/regi-check',
      name:'regi-check',
      component:RC_RegiCheck,
      meta:{
        requiresAuth: true
      }      
    },
    {
      path: '/my-ticket',
      name:'my-ticket',
      component:RC_MyTicket,
      meta:{
        requiresAuth: true
      }      
    },    
    {
      path: '/contact',
      name:'contact',
      component:RC_Contact,
      meta:{
        requiresAuth: false
      }      
    },      
    {
      path: '/customer-profile',
      name:'customer-profile',
      component:RC_CustomerProfile,
      meta:{
        requiresAuth: true
      }
    },
    {
      path: '/detail-concert',
      name:'detail-concert',
      component:RC_DetailConcert,
    },
    {
      path: '/tokusho',
      name:'tokusho',
      component:RC_Tokusho,
    },
    {
      path: '/privacy-policy',
      name:'privacy-policy',
      component:RC_PrivacyPolicy,
    },
    {
      path: '/my-concert-detail',
      name: 'artist-my-concert-detail',
      component: RC_A_MyConcertDetail,
      meta:{
       requiresAuth: true
      }
    },
    {
      path: '/my-concert',
      name: 'artist-my-concert',
      component: RC_A_MyConcert,
      meta:{
       requiresAuth: true
      }
    },
    {
      path: '/ticket-check',
      name: 'artist-ticket-check',
      component: RC_TicketCheck,
      meta:{
        requiresAuth: true
      }
    },
    {
      path: '/artist',
      component: RC_A_Home,
      meta: {
        requiresAuth:false
      },
      children:[         
        {
          path: 'test',
          name: 'test',
          component: RC_A_Test,
          meta:{
            requiresAuth: true
          }
        },       
      ]
    },     
    {
      path: '*',
      name: 'error',
      component:RC_Error
    },  
  ],
})

//ルーティング時のチェック
router.beforeEach((to, from, next) => {

  // 現在ログインしているユーザーを取得する
  //storeから引っ張るとタイミングがうまくいかないことがある
  let unsubscribe = auth.onAuthStateChanged((user) => {
    if (user) {
      user.getIdTokenResult()
        .then((idTokenResult) => {

          //開催者の場合
          if (idTokenResult.claims.role==="artist") {
          //   next({
          //     path: '/artist',
          //   })
          next()

          //お客さまの場合
          } else {
            // リダイレクトが設定されていればリダイレクト先に
            let redirect = window.localStorage.getItem('redirect')
            if (redirect) {
              next({
                path: redirect,
              })
              window.localStorage.removeItem("redirect")
            } else {
              // 認証ページに入る時、ログインされているならHome画面に行く
              if(to.name == "auth") {
                next({
                  path: '/',
                })
              } else {
                // // 認証済みでも、artistには行けない
                // if(to.matched.some(record => record.path==="/artist")) {
                //   next({
                //     path: '/',
                //   })
                // } else {
                  next()
                }
              }
            }
        })
        .catch(()=>{})
    } else {
      // 認証が必要かチェックし、必要であればAuthページに強制遷移
      if(to.matched.some(record => record.meta.requiresAuth)) {
        next({
          name: 'auth',
        })
        window.localStorage.setItem('redirect',to.fullPath)
      } else {
      // 認証が必要ない場合はそのままでOK
      next()
      }
    }
    unsubscribe()
  })

})


export default router
