<template>
  <v-container>
    <p class="text-center text-h3 my-7">プロフィールの確認・変更</p>
    <v-expansion-panels>
    <v-expansion-panel
    >
      <v-expansion-panel-header>
        氏名・電話番号の確認・変更
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-form
          ref="form"
          lazy-validation
        >
        <v-subheader class="pl-0">メール</v-subheader>
        <v-row>
          <v-col cols="12" class="pl-3">
            {{currentUser.email}}
          </v-col>
        </v-row>      
          <v-subheader class="pl-0">お名前</v-subheader>
          <v-row>
            <v-col cols="6" class="pb-0 pr-1">
              <v-text-field
                label="姓"
                v-model="targetUser.myouji"
                dense
                single-line
                :rules=[Rules.required]
                validate-on-blur
                :readonly="!edit"
                :outlined="edit"
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="pb-0 pl-1">
              <v-text-field
                v-model="targetUser.namae"
                label="名"
                dense
                single-line
                :rules=[Rules.required]
                validate-on-blur
                :readonly="!edit"
                :outlined="edit"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-subheader class="pl-0">ふりがな</v-subheader>
          <v-row>
            <v-col cols="6" class="pr-1">
              <v-text-field
                v-model="targetUser.myouji_kana"
                dense
                single-line
                label="せい"
                :rules=[Rules.required,Rules.kana]
                validate-on-blur
                :readonly="!edit"
                :outlined="edit"                
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="pl-1">
              <v-text-field
                v-model="targetUser.namae_kana"
                dense
                single-line
                label="めい"
                :rules=[Rules.required,Rules.kana]
                validate-on-blur
                :readonly="!edit"
                :outlined="edit"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-subheader class="pl-0">連絡先電話番号</v-subheader>
          <v-row>
            <v-col cols="12" class="pl-3">
              <v-text-field
                v-model="targetUser.tel"
                dense
                single-line
                hint="ハイフン無し。数字のみ。"
                :rules=[Rules.required,Rules.number]
                validate-on-blur
                :readonly="!edit"
                :outlined="edit"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="my-7" no-gutters v-if="!edit">
            <v-col class="text-center">
              <v-btn @click="edit=!edit" color="primary" outlined>編集する</v-btn>
            </v-col>
          </v-row>
          <v-row class="my-7" no-gutters v-else>
            <v-col class="text-center">
              <v-btn @click="dialog1=true" color="primary" :disabled="disabled">情報を更新する</v-btn>
              <v-btn @click="stopEdit()" color="primary" text :disabled="disabled">キャンセル</v-btn>
            </v-col>
          </v-row>                            
        </v-form>
        <v-dialog
      v-model="dialog1"
      width="500"
    >
      <v-card>
        <v-card-title class="grey lighten-2 py-1">
         確認
        </v-card-title>
        <p class="pl-3 pt-3 text-body1">お客様情報を更新します。よろしいですか？</p>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="updateProfile()"
          >
            更新する
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="stopEdit()"
          >
            キャンセル
          </v-btn>          
        </v-card-actions>
      </v-card>
    </v-dialog>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel
    >
      <v-expansion-panel-header>
        過去に購入したチケット
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row class="my-7" no-gutters>
        <v-col class="text-center">
        <v-btn @click="$router.push('/my-ticket')" color="primary" outlined>購入内容を確認する</v-btn>
        </v-col>
      </v-row>          
      </v-expansion-panel-content>
    </v-expansion-panel>

  </v-expansion-panels>    

  <v-row class="my-7" no-gutters>
        <v-col class="text-center">
        <v-btn @click="$router.push('/')" color="primary" outlined>TOPページへ</v-btn>
        </v-col>
  </v-row>  
  </v-container>

</template>

<script>
import {auth} from '../firebase'
import { mapGetters } from 'vuex'
import axios from 'axios'
import '../axios_setting.js'


export default {
  name: 'RC_CustomerProfile',
  created(){
  },
  components: {
  },

  data: () => ({
    Rules: {
          required: v => !!v || '入力必須です',
          number: v => /^[0-9０-９]+$/.test(v) || v==="" || '数字のみ入力してください',
          kana: v => /^[ぁ-んー]+$/.test(v) || 'ひらがなで入力してください',
          mail: v => /^[a-zA-Z0-9_+-]+(.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/.test(v)|| '入力内容を確認してください',
          maxlength: v => (v && v.length <= 11) || '入力内容を確認してください',
          minlength: v => (v && v.length >= 10) || '入力内容を確認してください'
    },
    edit:false,
    disabled:false,
    dialog1:false,
  }),
  computed:{
    ...mapGetters([
      'user',
      'currentUser',
    ]),
    targetUser:{
     get(){
        let copyUser
        if(this.user){
        copyUser = Object.assign({}, JSON.parse(JSON.stringify(this.user)));
        } else {
          copyUser={
            myouji:null,
            myouji_kana:null,
            namae:null,
            namae_kana:null,
            tel:null,
          }
        }
        return copyUser
     },
     set(item){
      return item
     }
    },
  },
  methods:{
    stopEdit(){
      this.edit = false
      this.dialog1 = false
      this.targetUser = Object.assign({}, JSON.parse(JSON.stringify(this.user)));
    },    
    updateProfile(){
      this.dialog1 = false
      if (this.$refs.form.validate()) {
        this.disabled = true
        this.$store.commit("set_loading",true)
        auth.currentUser.getIdToken(true).then(
        (idToken) => {
          axios.post(
              process.env.VUE_APP_API_ROOT+"/update-profile",
              { 
                idToken:idToken,
                email:this.currentUser.email,
                myouji:this.targetUser.myouji,
                namae:this.targetUser.namae,
                myouji_kana:this.targetUser.myouji_kana,
                namae_kana:this.targetUser.namae_kana,
                tel:this.targetUser.tel,
              },
              {withCredentials: true})
          .then(            
              () => {
                this.$store.commit("set_loading",false)
                this.$store.dispatch('set_alert', {
                  "type": "success",
                  "message":'更新が完了しました',
                  "timeout":3000
                })
              this.edit = false
              this.disabled = false
              })
          .catch(e => {
              console.error(e);
              });
        })
      } else {
        this.$store.dispatch('set_alert', {
          "type": "error",
          "message":'入力内容をご確認ください。',
          "timeout":3000
        })
      }
    },
  }
};
</script>
<style lang="scss">
</style>
