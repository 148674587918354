<template>
  <v-container>
    <p class="text-center text-h3 my-7">主催するコンサートを確認</p>
    <div v-if="myConcerts.length>0">
    <v-card
    class="mx-auto my-6"
    max-width="344"
    v-for="item in myConcerts" :key="item.id"
    @click="$router.push('/my-concert-detail?id='+item.id)"
  >
    <v-card-text>
      <p class="text-h3 font-weight-bold">
        {{item.name}}
      </p>
    </v-card-text>
  </v-card>
  </div>
  <div v-else><v-card max-width="500" class="mx-auto"><v-card-title>主催するコンサートがありません。</v-card-title></v-card></div>

  <v-row class="my-7" no-gutters>
        <v-col class="text-center">
        <v-btn @click="$router.push('/')" color="primary" outlined>ホームへ</v-btn>
        </v-col>
        <v-col class="text-center">
        <v-btn @click="$router.push('/ticket-check')" color="primary" outlined>チケット確認へ</v-btn>
        </v-col>

  </v-row>  
  </v-container>

</template>

<script>
import { mapGetters } from 'vuex'


export default {
  name: 'RC_A_MyConcert',
  created(){
  },
  components: {
  },

  data: () => ({
    Rules: {
          required: v => !!v || '入力必須です',
          number: v => /^[0-9０-９]+$/.test(v) || v==="" || '数字のみ入力してください',
          kana: v => /^[ぁ-んー]+$/.test(v) || 'ひらがなで入力してください',
          mail: v => /^[a-zA-Z0-9_+-]+(.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/.test(v)|| '入力内容を確認してください',
          maxlength: v => (v && v.length <= 11) || '入力内容を確認してください',
          minlength: v => (v && v.length >= 10) || '入力内容を確認してください'
    },
    edit:false,
    disabled:false,
    dialog1:false,
  }),
  computed:{
    ...mapGetters([
      'user',
      'currentUser',
      'concerts',
    ]),
    myConcerts() {
      return this.concerts.filter(item=>item.artist_uid===this.currentUser.uid)
    }
  },
  methods:{
  }
};
</script>
<style lang="scss">
</style>
