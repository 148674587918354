<template>
  <v-container>
    <p class="text-center text-h3 my-7">コンサートを確認</p>
    <div v-if="!!selectedConcert">
      <p class="text-h2 font-weight-bold">{{ selectedConcert.name }}</p>
      <div class="concert-h1">
          開封状況
      </div>
      <p class="text-h3 font-weight-bold">購入者数：{{ nyujoTotal }}</p>
      <p class="text-h3 font-weight-bold">未開封チケット：{{ nyujoCheck }}</p>
      <div class="concert-h1">
          チケット購入者情報
      </div>
      <p class="text-h3 font-weight-bold">来場チケット</p>

      <div v-for="item in theTickets" :key="item.id">
        <v-card
        class="mx-auto my-1"
        v-if="!item.online"
        >
          <v-card-text>
          <p class="text-h2 mb-1">{{item.userName}}様</p>
          <div v-for="ticket in item.line_items.data" :key="ticket.id">
            <p class="text-h2 mb-1">{{ticket.ticketName}} : {{ticket.quantity}}枚</p>
          </div>
          <p class="text-right mb-1">ご購入金額：{{item.amount|priceLocaleString}}円</p>
          <p v-if="item.opened" class="text-h2 font-weight-bold blue--text mb-0">入場済み</p>
          <p v-else class="text-h4 font-weight-bold red--text mb-0">未入場</p>

          </v-card-text>
        </v-card>
      </div>
    </div>
  <div v-else><v-card max-width="500" class="mx-auto"><v-card-title>主催するコンサートがありません。</v-card-title></v-card></div>

  <v-row class="my-7" no-gutters>
        <v-col class="text-center">
        <v-btn @click="$router.push('/')" color="primary" outlined>ホームへ</v-btn>
        </v-col>
        <v-col class="text-center">
        <v-btn @click="$router.go(-1)" color="primary" outlined>戻る</v-btn>
        </v-col>
  </v-row>  
  </v-container>

</template>

<script>
import { mapGetters } from 'vuex'


export default {
  name: 'RC_A_MyConcert',
  created(){
  },
  components: {
  },
  data: () => ({
    Rules: {
          required: v => !!v || '入力必須です',
          number: v => /^[0-9０-９]+$/.test(v) || v==="" || '数字のみ入力してください',
          kana: v => /^[ぁ-んー]+$/.test(v) || 'ひらがなで入力してください',
          mail: v => /^[a-zA-Z0-9_+-]+(.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/.test(v)|| '入力内容を確認してください',
          maxlength: v => (v && v.length <= 11) || '入力内容を確認してください',
          minlength: v => (v && v.length >= 10) || '入力内容を確認してください'
    },
    edit:false,
    disabled:false,
    dialog1:false,
  }),
  computed:{
    ...mapGetters([
      'user',
      'currentUser',
      'concerts',
      'alltickets',
      'users'
    ]),
    selectedConcert() {
      return this.concerts.find(item=>item.id===this.myConcertId)
    },
    myConcertId(){
      return this.$route.query.id
    },
    nyujoCheck(){
      let counter = 0
      for(let i=0;i<this.theTickets.length;i++){
        if(!this.theTickets[i].opened&&this.theTickets[i].online==false){
          counter++
        }
      }
      return counter
    },
    nyujoTotal(){
      let counter = 0
      for(let i=0;i<this.theTickets.length;i++){
        if(this.theTickets[i].online==false){
          counter++
        }
      }
      return counter
    },
    theTickets(){
      //表示用に、コンサート名称やチケット名称を追加
      let result = []
      //deepcopyしないとstate.regiItemsと連動してしまう
      let original = JSON.parse(JSON.stringify(this.alltickets.filter(item=>item.concertId===this.myConcertId)))
      if(this.concerts.length>0){
        for(let i=0;i<original.length;i++){
          //購入日
          original[i].createdAtFormated = this.formatedDate(this.alltickets[i].createdAt.toDate())
          original[i].createdAt = this.alltickets[i].createdAt.toDate()
          //コンサート名称まわり
          let concertId = original[i].concertId
          let the_concert = this.concerts.find(ele => ele.id === concertId)
          original[i].concertName = the_concert.name
          //コンサートチケットまわり
          for(let j=0;j<original[i].line_items.data.length;j++){
            let onlineOnly
            let ticketId = original[i].line_items.data[j].price.id
            let ticketName = the_concert.tickets.find(ele => ele.id === ticketId).name
            let online = the_concert.tickets.find(ele => ele.id === ticketId).online
            original[i].line_items.data[j].ticketName = ticketName
            original[i].line_items.data[j].online = online
            if (original[i].line_items.data.length==1&&original[i].line_items.data[0].online==true){
              onlineOnly =true
            } else {
              onlineOnly = false
            }
            original[i].online = onlineOnly
            }
          //購入者
          let theUser = this.users.find(ele => ele.uid === original[i].uid)
          let userName = theUser.myouji + theUser.namae + "(" + theUser.myouji_kana + theUser.namae_kana + ")"
          original[i].userName = userName
          result.push(original[i])
        }
      } else {
        result.push(
          {
            concertId:null,
            concertName:null,
            createdAt:new Date(),
            createdAtFormated:this.formatedDate(new Date()),
            line_items:
              {
                data:
                [{
                  price:0,
                  quantity:null,
                  ticketName:null,
                  }
                ]
              }
          })
      }
      //購入が最近のものを上へ表示
      const resultSorted = result.sort((a, b) => b.createdAt - a.createdAt)
      return resultSorted

    },
  },
  methods:{
    formatedDate(a){
      let year = a.getFullYear()
      let month = a.getMonth()+1
      let date = a.getDate()
      let hour = a.getHours()
      let minute = a.getMinutes()
      return `${year}年${month}月${date}日 ${hour}時${minute}分`
    },
  }
};
</script>
<style lang="scss">
.concert-h1{
  margin-top: 30px;
  margin-bottom: 10px;
  font-size: 1.2rem;
  border-bottom: 1px solid #ccc;
}
</style>
