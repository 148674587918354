<template>
  <v-container>
    <p class="text-center text-h3 my-7">購入済みチケット</p>
    <div v-if="tickets.length>0">
    <v-card
    class="mx-auto my-6"
    max-width="344"
    v-for="item in formedTickets" :key="item.id"
  >
    <v-card-text>
      <div class="mb-2">購入日時: {{item.createdAtFormated}}</div>
      <p class="text-h3 font-weight-bold">
        {{item.concertName}}
      </p>
      <div v-for="ticket in item.line_items.data" :key="ticket.id">
        <p class="text-h2">{{ticket.ticketName}} : {{ticket.quantity}}枚</p>
      </div>
      <p class="text-h5 text-right">ご購入金額：{{item.amount|priceLocaleString}}円</p>
    </v-card-text>
    <v-card class="ticket-wrapper" elevation="0" v-if="!(item.line_items.data.length===1&&item.line_items.data[0].online===true)">
      <v-divider></v-divider>
      <v-card-title>入場用QRコード</v-card-title>
      <vue-qrcode :value="item.id" :options="option"></vue-qrcode>
      <v-subheader>チケット使用状況</v-subheader>
      <v-row no-gutters>
        <v-col>
          <div v-if="item.opened" class="accent2 py-1">
            <v-icon v-if="item.opened" color="primary" class="pb-1">mdi-check-outline</v-icon>
            <span class="ml-2 primary--text">QRコード入場チェック済み</span>
          </div>
          <div v-else class="subtle py-1">チケット未使用</div>
        </v-col>
      </v-row>
    </v-card>
    <v-card class="ticket-wrapper" elevation="0" v-else>
      <v-card-text class="text-left">オンライン配信はYoutubeを用いて行う予定です。詳細はオンライン視聴チケットをご購入者の方へ別途ご案内申し上げます。</v-card-text>
    </v-card>
  </v-card>
  </div>
  <div v-else><v-card max-width="500" class="mx-auto"><v-card-title>購入済みチケットはありません。</v-card-title></v-card></div>
  <v-row class="ticket-btn-area" no-gutters>
        <v-col class="text-center my-7">
        <v-btn @click="$router.push('/')" color="primary" outlined>TOPページへ</v-btn>
        </v-col>
  </v-row>

 <v-overlay :value="overlay">
  <v-card flat class="black--text ma-2" color="white">
      <v-row class="pt-5">
        <v-col class="text-center">
          <v-icon size="70" class="text-center mx-auto" align="center" color="green">mdi-check-circle-outline</v-icon>
        </v-col>
      </v-row>
      <v-card-title class="text-h3">ご来場ありがとうございます</v-card-title>

    <v-card-text class="black--text">チケットを確認しました。ご入場いただけます。</v-card-text>

        <v-row class="my-5">
          <v-col class="text-center">
            <v-btn class="text-center" align="center" color="primary" @click="overlay = !overlay" outlined>閉じる</v-btn>
          </v-col>        
        </v-row> 
    </v-card> 

</v-overlay>
 </v-container>
</template>

<script>
import VueQrcode from '@chenfengyuan/vue-qrcode';
import { mapGetters } from 'vuex'

export default {
  name: 'RC_MyTicket',
  components: {
    VueQrcode
  },

  data: () => ({
    overlay:false,
    option: {
        errorCorrectionLevel: "M",
        maskPattern: 0,
        scale: 2,
        width: 250,
        color: {
          dark: "#000000FF",
          light: "#FFFFFFFF"
        }
    },
  }),
  computed:{
    ...mapGetters([
      'tickets',
      'user',
      'concerts',
      'changedTicket',
    ]),     
    openedTickets(){
      if(this.tickets.length>0){
        return this.tickets.filter(item=>item.opened===true).length
      }
        return -1
    },
    formedTickets(){
      //表示用に、コンサート名称やチケット名称を追加
      let result = []
      //deepcopyしないとstate.regiItemsと連動してしまう
      let original = JSON.parse(JSON.stringify(this.tickets))
      if(this.concerts.length>0){
        for(let i=0;i<original.length;i++){
          //購入日
          original[i].createdAtFormated = this.formatedDate(this.tickets[i].createdAt.toDate())
          original[i].createdAt = this.tickets[i].createdAt.toDate()
          //コンサート名称まわり
          let concertId = original[i].concertId
          let the_concert = this.concerts.find(ele => ele.id === concertId)
          original[i].concertName = the_concert.name
          //コンサートチケットまわり
          for(let j=0;j<original[i].line_items.data.length;j++){
            let ticketId = original[i].line_items.data[j].price.id
            let ticketName = the_concert.tickets.find(ele => ele.id === ticketId).name
            let online = the_concert.tickets.find(ele => ele.id === ticketId).online
            original[i].line_items.data[j].ticketName = ticketName
            original[i].line_items.data[j].online = online
            }
          result.push(original[i])
        }
      } else {
        result.push(
          {
            concertId:null,
            concertName:null,
            createdAt:new Date(),
            createdAtFormated:this.formatedDate(new Date()),
            line_items:
              {
                data:
                [{
                  price:0,
                  quantity:null,
                  ticketName:null,
                  }
                ]
              }
          })
      }
      //購入が最近のものを上へ表示
      const resultSorted = result.sort((a, b) => b.createdAt - a.createdAt)
      return resultSorted
    },
    displayFormedChangedTicket(){
      let copy = JSON.parse(JSON.stringify(this.formedTickets))
      return copy.find(item=>item.id===this.changedTicket.id)
    },
  },
  watch: {
    openedTickets: function(next,prev) {
      //チケット読み込み後のopendのtrue数の変化に限定
      if(prev<next&&prev>-1) {
        // this.$store.dispatch('set_alert',{"type":"success","message":`ご来場ありがとうございます！`})
      } else if(prev>next){
        // this.$store.dispatch('set_alert',{"type":"warning","message":`入場が取り消されました.`})
      }
    },
    overlay (val) {
        val && setTimeout(() => {
          this.overlay = false
        }, 10000)
      },
    changedTicket(){
      this.overlay = true
      console.log(this.changedTicket)
    },
  },
  methods:{
    formatedDate(a){
      let year = a.getFullYear()
      let month = a.getMonth()+1
      let date = a.getDate()
      let hour = a.getHours()
      let minute = a.getMinutes()
      return `${year}年${month}月${date}日 ${hour}時${minute}分`
    },
  },
};
</script>
<style lang="scss" scoped>
.ticket-wrapper{
  text-align: center;
  &canvas{
    margin:0 auto;
  }
}
.ticket-btn-area{
  height:80px;
}



</style>
