<template>
  <v-container>
    テスト
  </v-container>

</template>

<script>


export default {
  name: 'RC_A_Test',
  created(){
  },
  components: {
  },

  data: () => ({
  }),
  computed:{
  },
  methods:{
  }
};
</script>
<style lang="scss">
</style>
