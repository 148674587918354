<template>
    <router-view></router-view>
</template>

<script>


export default {
  name: 'RC_A_Home',
  components: {
  },

  data: () => ({
  }),
  computed:{
  },
  methods:{
  },
  watch:{
  }
};
</script>
<style lang="scss">
/* エミュレーターの注意書きを削除 */
.firebase-emulator-warning { display: none; }
</style>
