<template>
  <v-container>
    <p class="my-5 text-center text-h3">特定商取引法に基づく表記</p>
    <v-row>
      <v-col class="text-center">
        <img src="@/assets/tokusho.png" alt="特定商取引法に基づく表記" class="tokusho_img">
      </v-col>
    </v-row>

    <v-row class="my-7" no-gutters>
        <v-col class="text-center">
        <v-btn @click="$router.push('/')" color="primary" outlined>ホームへ</v-btn>
        </v-col>
  </v-row>

  </v-container>

</template>

<script>


export default {
  name: 'RC_Tokusho',
  components: {
  },

  data: () => ({
  }),
  computed:{
  },
  methods:{
  }
};
</script>
<style lang="scss">

.tokusho_img{
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}
</style>
