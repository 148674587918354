<template>
  <v-container max-width="">
    <v-card flat class="text-h1 text-center mt-4">
      <v-row class="pt-5">
        <v-col class="text-center">
          <v-icon size="70" class="text-center mx-auto" align="center" color="green">mdi-check-circle-outline</v-icon>
        </v-col>
      </v-row>      
      ご購入ありがとうございます
    </v-card>    
    <v-card flat class="pa-3 mx-auto" max-width="400px" width="100%">
      決済が完了しました。<br>ご登録のメールアドレス宛てに、購入内容確認メールが自動送信されますのでご確認ください。
      <v-row class="my-5">
        <v-col class="text-center">
          <v-btn class="text-center" align="center" color="secondary" @click="$router.push('/my-ticket')">購入チケット一覧</v-btn>
        </v-col>
      </v-row>
      <v-row class="my-5">
        <v-col class="text-center">
          <v-btn class="text-center" align="center" color="secondary" @click="$router.push('/')" outlined>ホーム</v-btn>
        </v-col>        
      </v-row>        
    </v-card> 
    </v-container>
</template>

<script>
// import axios from 'axios'
// import '../axios_setting.js'
// import {auth} from '../firebase'

export default {
  name: 'App',

  components: {
  },

  data: () => ({
    customer:null
  }),
  computed:{
    session_id() {
      return this.$route.query.session_id
    },
  },
  created(){
    this.$store.commit("set_loading",false)
    // auth.currentUser.getIdToken(true).then(
    //   (idToken) => { 
    //     axios.get(
    //       process.env.VUE_APP_API_ROOT+"/order/success",{
    //         params:{
    //           session_id:this.session_id,
    //           idToken
    //         }
    //       })
    //     .then(
    //       res => {
    //         this.customer = res.data.customer
    //         console.log(res.data.customer)
    //         console.log(res.data.session)
    //         this.$store.commit("set_loading",false)
    //       })
    //     .catch(e => {
    //       console.error(e);
    //       this.$store.commit("set_loading",false)
    //     })
    //   }
    // )
  },
  methods:{
  }
};
</script>
<style lang="scss">
/* エミュレーターの注意書きを削除 */
.firebase-emulator-warning { display: none; }
</style>
