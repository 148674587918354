<template>
<div>
  <p class="my-5 text-center text-h3">チケット販売中のコンサート</p>
  <v-card v-if="onSales.length<1" outlined class="concert-card mx-auto">
    販売中のコンサートがありません
  </v-card>
  <v-card v-for="concert in onSales" tile :key="concert.id" class="concert-card mx-auto my-5" @click="$router.push('/detail-concert?id='+concert.id)">
    <div class="accent2 pa-3 pa-md-5 concert-pic-wrap">
      <img src="@/assets/2.jpg" alt="concert" class="concert-pic elevation-8">
    </div>
    <div class="pa-3">
      <p class="concert-name">{{concert.name}}</p>
      <p class="text-left text-caption mb-0">2024年3月30日(土)</p>
      <p class="text-left text-caption mb-0">オンライン同時配信あり</p>
    </div>    
    <div class="text-right pa-3" @click="$router.push('/detail-concert?id='+concert.id)">
        <v-btn  small color="primary" outlined>詳細を見る</v-btn>
      </div>
  </v-card>
  <!-- <p class="my-5 text-center text-h3">販売終了したコンサート</p>
  <v-card v-if="notOnSales.length<1" outlined class="concert-card mx-auto">
    該当のコンサートがありません
  </v-card>
  <v-card v-for="concert in notOnSales" tile :key="concert.id" class="concert-card mx-auto" @click="$router.push('/detail-concert?id='+concert.id)">
    <div class="accent2 pa-3 pa-md-5 concert-pic-wrap">
      <img src="@/assets/0.png" alt="concert" class="concert-pic elevation-8">
    </div>
    <div class="pa-3">
      <p class="concert-name">{{concert.name}}</p>
      <p class="text-left text-caption mb-0">2023年4月2日 14:15開演</p>
      <p class="text-left text-caption mb-0">タカギクラヴィア 松濤サロン</p>
    </div>    
    <div class="text-right pa-3" @click="$router.push('/detail-concert?id='+concert.id)">
        <v-btn  small color="primary" outlined>詳細を見る</v-btn>
      </div>
  </v-card> -->

</div>

</template>

<script>

export default {
  name: 'RiccoListConcert',
  created(){
  },
  components: {
  },

  data: () => ({
  }),
  computed:{
    concerts() {
      return this.$store.getters.concerts
    },
    onSales() {
      return this.concerts.filter(item=>item.onSale===true)
    },
    notOnSales() {
      return this.concerts.filter(item=>item.onSale===false)
    },    
  },
  methods:{
  }
};
</script>

<style lang="scss">
.concert-card{
  width: 80%;
  max-width: 400px;
}

.concert-pic{
  display: block;
  margin: 0 auto;
  width: 50%;
  max-width: 150px;
}

.concert-name{
  font-size: 1rem;
  font-weight: bold;
  text-align: left;
}
</style>
