<template>
  <v-container class="wrapper px-5 px-sm-7">
    <div class="pp-title my-5">プライバシーポリシー</div>  
    <div class="pp-content">個人情報の保護に関する法律に対応するため、riccoチケット(以下「本サイト」といいます。)は、個人情報の保護方針を定め、個人情報の情報管理の徹底とサービス向上への有効利用を図ることとしました。 これに伴い、本サイトは、法令を遵守し、お客様に関わる個人情報を下記のとおり取り扱い、個人情報を保護し、個人の権利及び利益を守るよう努めます。</div>
    <div class="subtitle">
      個人情報保護に関する法令等を遵守
    </div>
    <div class="pp-content">本サイトでは、個人情報の保護に関する関係法令等を遵守し、個人情報を適切に取扱います。</div>
    <div class="subtitle">
      個人情報の取得について
    </div>
    <div class="pp-content">本サイトが個人情報を取得する場合は、利用目的を明確にしたうえ、適切な方法により取得するものとします。</div>
    <div class="subtitle">
      個人情報の利用等について
    </div>
    <div class="pp-content">本サイトが取得した個人情報は、前項２によって取得した目的及びその範囲内であると判断される場合、本サイトが行うサービスに必要な情報として利用します。また、個人情報の取扱いを第三者に委託する場合は、当該の第三者に対し、適切な利用となるよう十分なる監督を行います。</div>
    <div class="subtitle">
      個人情報の第三者提供
    </div>
    <div class="pp-content">本サイトは、あらかじめお客様の同意を得ることなく、第三者へ個人データを提供することはありません。ただし、法令及び規程の定めるところにより、所定の個人データを本サイト関係団体等に提供することがあります。この場合には、提供先において当該個人データの紛失、破壊、改ざん及び漏洩等の不正な取扱いがなされないよう約定し、適切な管理を実施させます。</div>
    <div class="subtitle">
      個人情報の管理等について
    </div>
    <div class="pp-content">本サイトは、業務を委託するために個人データを事業者に預託する場合は、当該個人データの紛失、破壊、改ざん及び漏洩等の不正な取扱いがなされないよう約定し、当該事業者に対し必要かつ適切な監督を行います。</div>
    <div class="subtitle">
      個人情報の開示、内容の訂正、追加・削除、利用の停止、消去
    </div>
    <div class="pp-content">本サイトは、個人情報について本人から開示、内容の訂正、追加・削除、利用の停止、消去などの請求があった場合、法令に基づき迅速に対応します 。</div>
    <div class="subtitle">
      開示等請求の手続
    </div>
    <div class="pp-content">本サイトが定める「保有個人データ開示等請求書」の様式により開示等請求をお受けします。お問い合わせフォームまでご連絡ください。</div>

    <v-row class="" no-gutters>
        <v-col class="text-center my-7">
        <v-btn @click="$router.push('/')" color="primary" outlined>ホームへ</v-btn>
        </v-col>
  </v-row>

  </v-container>
</template>

<script>

export default {
  name: 'RC_PrivacyPolicy',
  created(){
  },
  components: {
  },
  data: () => ({
  }),
  computed:{
  },
  methods:{
  }
}
</script>

<style scoped lang="scss">
.wrapper{
  width: 100%;
  background-color: white;
  max-width: 600px;
}


.pp-title{
  font-size: 1.4rem;
  font-weight: bold;
}

.subtitle{
  margin-top: 30px;
  margin-bottom: 10px;
  font-size: 1.2rem;
  border-bottom: 1px solid #ccc;
}
.pp-content{
    font-size: 0.9rem;
    margin-bottom: 5px;
    line-height: 25px;
    text-align: justify;
    text-justify: inter-ideograph;
    white-space: pre-wrap;
  }
</style>
