/* eslint-disable no-console */
import Vue from 'vue'
import Vuex from 'vuex'
import {db,auth,storage} from '../firebase'
import {ShortId} from '../plugins/uuid'



Vue.use(Vuex)

const dbpull = (collection,docID) => db.collection(collection).doc(docID).get()
const dbpush = (collection,docID,item) => db.collection(collection).doc(docID).set(item,{ merge: true })
// const dbpush_no_id = (collection,item) => db.collection(collection).add(item,{ merge: true })

let store = new Vuex.Store({
    state: {
        loading:false,
        currentUser:null,
        user:{},
        users:[],
        tickets:[],
        alltickets:[],
        concerts:[],
        concert:null,
        concertPics:[],
        regiItems:[],
        alerts:{},
        changedTicket:{},
        changedallTicket:{},
        unsub_tickets:null,
        unsub_concerts:null,
        unsub_users:null,
        unsub_user:null,
        myConcetsTickets:[],
    },
    mutations: {
        set_loading(state, payload) {
            state.loading = payload;
        },
        set_ticket(state,payment) {
            state.tickets.push(payment)
        },
        set_allticket(state,payment) {
            state.alltickets.push(payment)
        },
        set_concert(state,concert) {
            state.concerts.push(concert)
        },
        set_user(state,user){
            state.user = user
        },
        set_users(state,user){
            state.users.push(user)
        },
        set_currentUser(state,user) {
            state.currentUser = user
        },
        set_changedTicket(state,ticket){
            state.changedTicket = ticket
        },
        set_changedallTicket(state,ticket){
            state.changedallTicket = ticket
        },
        set_regiItems(state,item) {
            let concertId = item.concertId
            let regiItemsTargetIndex = state.regiItems.findIndex(el=>el.concertId = concertId)
            if (regiItemsTargetIndex>-1) {
                for(let i=0;i<item.tickets.length;i++){                
                    let ticketsArrayTaragetIndex = state.regiItems[regiItemsTargetIndex].tickets.findIndex(el=>el.price===item.tickets[i].price)
                    if(ticketsArrayTaragetIndex>-1){
                        state.regiItems[regiItemsTargetIndex].tickets[ticketsArrayTaragetIndex].quantity += item.tickets[i].quantity
                    } else{
                        state.regiItems[regiItemsTargetIndex].tickets.push(item.tickets[i])
                    }
                }
            } else {
                state.regiItems.push(item)
            }
            dbpush("regi",state.currentUser.uid,{"regiItems":state.regiItems,"uid":state.currentUser.uid})
            // window.localStorage.setItem('regiItems',JSON.stringify(state.regiItems))
        },
        delete_all_regiItems(state){
            state.regiItems= []
            dbpush("regi",state.currentUser.uid,{"regiItems":[]})
            // window.localStorage.removeItem('regiItems')
        },
        delete_regiItems(state,{concertId,priceId}){
            let p = state.regiItems.findIndex(i=>i.concertId === concertId)
            let q = state.regiItems[p].tickets.findIndex(i=>i.price==priceId)
            state.regiItems[p].tickets.splice(q,1)
            //チケットがひとつもないかどうか
            let r = state.regiItems[p].tickets.length<1
            //チケット要素はあるが、数量が0
            let s = state.regiItems[p].tickets.every(element => element.quantity<1)
            if(r){
                state.regiItems.splice(p,1)
            } else if(s) {
                state.regiItems.splice(p,1)
            }
            dbpush("regi",state.currentUser.uid,{regiItems:state.regiItems})
            // window.localStorage.setItem('regiItems',JSON.stringify(state.regiItems))
        },
    },
    actions: { 
        initialize({state}) {
            Object.assign(state,{
                loading:false,
                currentUser:null,
                user:{},
                tickets:[],
                alltickets:[],
                //concertsはリセットしない
                concert:null,
                concertPics:[],
                regiItems:[],
                alerts:{},
            },)
        },
        on_logout({state}){
            if(state.unsub_tickets!==null&&state.unsub_users!==null){
            state.unsub_tickets()
            state.unsub_alltickets()

            state.unsub_users()
            state.unsub_user()
            }
        },
        sync_users_firesotre({state,commit}){
            // if(state.user.role==="admin"){
                state.unsub_users = db.collection("users").onSnapshot((querySnapshot) => {
                    querySnapshot.docChanges().forEach((change) => {
                        // 追加
                        if (change.type === 'added') {
                            commit('set_users',change.doc.data())
                        }
                        // 修正（更新時）
                        if (change.type === 'modified') {
                            let index = state["users"].findIndex((item)=>item.id === change.doc.data().id)
                            state["users"].splice(index,1,change.doc.data())
                        }
                        // 完全削除時
                        if (change.type === 'removed') {
                            //要mutation化
                            let index = state["users"].findIndex((item)=>item.id === change.doc.data().id)
                            state["users"].splice(index,1)
                        }
                    })
                })
            // }
        },
        sync_user_firesotre({state,commit}){
            state.unsub_user = db.collection("users").where("uid", "==", state.currentUser.uid).onSnapshot((querySnapshot) => {
                querySnapshot.docChanges().forEach((change) => {
                    // 追加
                    if (change.type === 'added') {
                        commit('set_user',change.doc.data())
                    }
                    // 修正（更新時）
                    if (change.type === 'modified') {
                        commit('set_user',change.doc.data())
                    }
                    // 完全削除時
                    if (change.type === 'removed') {
                        state.user = null
                    }
                })
            });
        },
        sync_tickets_firesotre({state,commit}){
            state.unsub_tickets = db.collection("tickets").where("uid", "==", state.currentUser.uid).onSnapshot((querySnapshot) => {
                // state.unsub_tickets = db.collection("tickets").onSnapshot((querySnapshot) => {

                querySnapshot.docChanges().forEach((change) => {
                    // 追加
                    if (change.type === 'added') {
                        commit('set_ticket',change.doc.data())
                    }
                    // 修正（更新時）
                    if (change.type === 'modified') {
                        let index = state["tickets"].findIndex((item)=>item.id === change.doc.data().id)
                        state["tickets"].splice(index,1,change.doc.data())

                        //開封時のポップアップ用にchangedTicketに代入
                        if(change.doc.data().opened===true){
                        commit('set_changedTicket',change.doc.data())
                        }
                    }
                    // 完全削除時
                    if (change.type === 'removed') {
                        //要mutation化
                        let index = state["tickets"].findIndex((item)=>item.id === change.doc.data().id)
                        state["tickets"].splice(index,1)
                    }
                })
            });
        },
        sync_alltickets_firesotre({state,commit}){
                state.unsub_alltickets = db.collection("tickets").where("artist_uid", "==", state.currentUser.uid).onSnapshot((querySnapshot) => {
                querySnapshot.docChanges().forEach((change) => {
                    // 追加
                    if (change.type === 'added') {
                        commit('set_allticket',change.doc.data())
                    }
                    // 修正（更新時）
                    if (change.type === 'modified') {
                        let index = state["alltickets"].findIndex((item)=>item.id === change.doc.data().id)
                        state["alltickets"].splice(index,1,change.doc.data())

                        //開封時のポップアップ用にchangedTicketに代入
                        if(change.doc.data().opened===true){
                        commit('set_changedallTicket',change.doc.data())
                        }
                    }
                    // 完全削除時
                    if (change.type === 'removed') {
                        //要mutation化
                        let index = state["alltickets"].findIndex((item)=>item.id === change.doc.data().id)
                        state["alltickets"].splice(index,1)
                    }
                })
            });
        },
        sync_concerts_firesotre({state,commit}){
            let result = new Promise((resolve)=>{
                state.unsub_concerts = db.collection("concerts").onSnapshot((querySnapshot) => {
                    querySnapshot.docChanges().forEach((change) => {
                        // 追加
                        if (change.type === 'added') {
                            commit('set_concert',change.doc.data())
                        }
                        // 修正（更新時）
                        if (change.type === 'modified') {
                            let index = state["concerts"].findIndex((item)=>item.id === change.doc.data().id)
                            state["concerts"].splice(index,1,change.doc.data())
                        }
                        // 完全削除時
                        if (change.type === 'removed') {
                            //要mutation化
                            let index = state["concerts"].findIndex((item)=>item.id === change.doc.data().id)
                            state["concerts"].splice(index,1)
                        }
                    })
                })
                resolve()
            })
            return result
        },             
        sync_firestore ({commit,dispatch}) {
                //認証が無関係のcollectionから読み取り
                dispatch('sync_concerts_firesotre').then(()=>{})        
                auth.onAuthStateChanged((user) => {
                    if (user) {
                        commit('set_currentUser',user)
                        dispatch('get_regiItems',user)
                        dispatch('sync_user_firesotre')
                        dispatch('sync_users_firesotre')
                        dispatch('sync_tickets_firesotre')
                        dispatch('sync_alltickets_firesotre')

                    } else {
                        //snapshotリスナーの解除
                        dispatch("on_logout")
                        //stateの初期化
                        dispatch('initialize')
                    }
                })
        },
        get_regiItems({state},user){
            //sync_firestoreで呼び出し
            let storageItems
            dbpull("regi",user.uid).then(doc=>{
                if (doc.exists) {
                    storageItems = doc.data().regiItems
                    state.regiItems = storageItems
                } else {
                    state.regiItems = []
                }                
            }).catch(error=>{console.log(error,"エラー")})
        },
        async get_concertPics({state,commit},amount,id){
                amount=4
                id="concert"
                let result = []
                commit("set_loading",true)
                for(let i=0 ; i<amount;i++){
                    await storage.ref(`${id}/${i}.jpg`).getDownloadURL()
                    .then((url) => {
                        result.push(url)
                    })
                }
                state.concertPics =  result
                commit("set_loading",false)
            return new Promise((resolve,reject)=>{
                if(result.length>0){
                    resolve()
                }else{
                    reject()
                }
            })
        },
        get_concert({state,commit},id){
            return new Promise((resolve,reject)=>{
                commit("set_loading",true)
                dbpull("concerts",id).then((doc)=>{
                    if (doc.exists) {
                        state.concert = doc.data()
                        commit("set_loading",false)
                        resolve()
                    } else {
                        state.concert = null;
                        commit("set_loading",false)
                        resolve()
                    }                
                }).catch(() => {
                    state.concert = null;
                    commit("set_loading",false)
                    reject()
                })
            })            
        },
        set_alert({state},alert) {
            let timeout = 2500
            let key = ShortId()
            if (alert.timeout) {timeout = alert.timeout}
            //pluginで短いidを生成し、それをalertsオブジェクトのkeyとして各alertと紐付け
            //リアクティブになるようにsetでalertをalertsへ追加
            Vue.set(state.alerts, key, alert);
            setTimeout(() => {
              Vue.delete(state.alerts, key);
            }, timeout);
          },        
    },    
    getters: {
        loading:state=>state.loading,
        tickets: state => state.tickets,
        alltickets: state => state.alltickets,
        currentUser: state => state.currentUser,
        concerts: state => state.concerts,
        concert:state => state.concert,
        concertPics:state => state.concertPics,
        regiItems:state=> state.regiItems,
        alerts:state=> state.alerts,
        user:state=>state.user,
        users:state=>state.users,
        changedTicket:state=>state.changedTicket,
        changedallTicket:state=>state.changedallTicket,

    },
});


export default store;