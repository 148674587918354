<template>
  <v-container fill-height fluid color="subtle">
      <v-card class="py-5 px-3 mx-auto" max-width="400px" width="100%">
        <div v-if="newCustomer" class="text-h6 pb-5 text-center">新規会員登録</div>
        <div v-else class="text-h3  text-center">ログイン</div>
        <v-card class="my-5" flat>
          <v-row justify="center" no-gutters class="pt-2">
            <v-col cols="auto" class="">
              <v-btn
                tile
                :loading="loading1"
                :disabled="disabled1"
                color="primary"
                outlined
                class="my-2 login-btn"
                @click="signInWithGoogle"
              >
              <img src="@/assets/google.svg" alt="google" class="btn-logo pr-3" />
              Googleアカウントでログイン
              </v-btn>          
            </v-col>
          </v-row>
        </v-card>
        <v-expansion-panels flat>
          <v-expansion-panel>
            <v-expansion-panel-header >
              または、メールアドレスでログイン
            </v-expansion-panel-header>
            <v-expansion-panel-content >
              <v-card class="mail-login-explanation text-caption pb-2" flat>
                入力いただいたアドレスにログイン用URLをお送りします。
              </v-card>
              <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-row justify="center" align-items="center" align-content="center" class="">
            <v-col cols="12" class="pb-0">
              <v-text-field
                v-model="email"
                color="primary"
                dense
                outlined
                label="example@piano.com"
                single-line
                :rules=[Rules.required,Rules.mail,Rules.mailmaxlength]
                validate-on-blur
                :disabled="disabled3"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row justify="center" no-gutters class="pt-2">
            <v-col cols="auto" class="">
              <v-btn
                @click="signIn"
                color="primary"
                tile
                :loading="loading3"
                :disabled="disabled3"            
              >メールアドレスでログイン</v-btn>
            </v-col>
          </v-row>
        </v-form>

            </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>        
      </v-card>

        <!-- <div class="my-6"><v-btn @click="$router.push('/')" color="blue" dark>ホームへ</v-btn></div> -->

    </v-container>
</template>

<script>
import {auth,provider} from '../firebase'
import axios from 'axios'
import '../axios_setting.js'


export default {
  name: 'App',

  components: {
  },
  mounted(){
    if (this.$store.currentUser) {
    this.$router.push('/');
    }    
  },
  data: () => ({
    Rules: {
      required: v => !!v || '入力必須です',
      number: v => /^[0-9０-９]+$/.test(v) || v==="" || '数字のみ入力してください',
      kana: v => /^[ぁ-ん]+$/.test(v) || 'ひらがなで入力してください',
      mail: v => /^[a-zA-Z0-9_+-]+(.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/.test(v)|| '入力内容を確認してください',
      maxlength: v => (v && v.length <= 11) || '入力内容を確認してください',
      mailmaxlength: v => (v && v.length <= 40) || 'このメールアドレスは使用できません。',
      minlength: v => (v && v.length >= 8) || '8文字以上としてください'
    },
    valid:true,
    email:"",
    password:"",
    nemail:"",
    npassword:"",
    newCustomer:false,
    disabled1:false,
    disabled3:false,
    loading1:false,
    loading3:false,    
  }),
  methods:{
    modeChange(){
      this.$refs.form.reset()
      this.newCustomer=!this.newCustomer
    },
    signIn(){
      if (this.$refs.form.validate()) { 
        this.disabled3=true
        this.loading3=true
        axios.post(
            process.env.VUE_APP_API_ROOT+"/generate-mail-link",
            {email:this.email
            },
            {withCredentials: true})
        .then(()=>{
          this.$store.dispatch('set_alert',{"timeout":60000,"type":"success","message":"ログイン用URLを記載したメールをお送りしました。メールをご確認ください。"})           
          window.localStorage.setItem('emailForSignIn', this.email)
          this.loading3=false
        })
        .catch(e => {
            console.error(e);
            });
      }
    },
    signInWithGoogle(){
      this.disabled1=true
      this.loading1=true
      let ua = this.detectInAppBrowser()
      if (ua === "line"){
        window.alert("申し訳ございません。LINEのアプリ内ブラウザではなく、SafariやChrome等のブラウザからアクセスいただくか、メールアドレスによるログイン方法をご利用ください。")
        this.loading1=false
        this.disabled1=false
      } else if (ua === "instagram"){
        window.alert("申し訳ございません。instagramのアプリ内ブラウザではなく、SafariやChrome等のブラウザからアクセスいただくか、メールアドレスによるログイン方法をご利用ください。")
        this.loading1=false
        this.disabled1=false
      } else if (ua === "facebook"){
        window.alert("申し訳ございません。facebookのアプリ内ブラウザではなく、SafariやChrome等のブラウザからアクセスいただくか、メールアドレスによるログイン方法をご利用ください。")
        this.loading1=false
        this.disabled1=false
      } else {
        this.$store.commit("set_loading",true)
        auth.signInWithPopup(provider)
            .then(() => {
                this.$router.push('/').catch(() => {})
                this.$store.commit("set_loading",false)
            })
            .catch((error)=>{
              switch (error.code){
                case 'auth/popup-blocked':
                  window.alert('ポップアップがブロックされました。当サイトでのポップアップを許可するか、メールアドレスによるログイン方法をお試しください。');
                  break;
                default:
                  window.alert('エラーが発生しました。');
              }            
              this.loading1=false
              this.disabled1=false
              this.$store.commit("set_loading",false)
            })
        }
    },
    signUp() {
      if (this.$refs.form.validate()) {
        auth.createUserWithEmailAndPassword(this.nemail, this.npassword)
        .then(() => {
          axios.post(
              process.env.VUE_APP_API_ROOT+"/generate-mail-verification",
              {email:this.nemail
              },
              {withCredentials: true})
          .then(()=>{
            this.$router.push('/auth-check').catch(()=>{});                      
          })
          .catch(e => {
            console.error(e);
          });
        })
        .catch(()=>{
        })
      }
    },
    detectInAppBrowser(){
      let ua = window.navigator.userAgent
      ua = ua.toLowerCase().trim();

      // Facebook
      if (ua.includes('fbios') || ua.includes('fb_iab')) {
      return "facebook"
      } else // Instagram
      if (ua.includes('instagram')) {
        return "instagram"
      } else
      // LINE
      if (ua.includes(' line/')) {
        return "line"
      } else {
        return "notfound"
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.mail-login-explanation{
}

.btn-logo{
  width:30px;
}

.login-btn{
  text-transform: none  
}
</style>
