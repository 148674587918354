<template>
  <v-container>
    <div class="text-body1">購入がキャンセルされました</div>
    <v-row class="my-5" justify="center" align="center">
      <v-col class="text-center">
      <v-btn @click="$router.replace('/')" color="secondary" tile>ホームへ</v-btn>
      </v-col>
    </v-row>

    </v-container>
</template>

<script>
import axios from 'axios'
import '../axios_setting.js'
import {auth} from '../firebase'


export default {
  name: 'RC_Cancel',

  components: {
  },
  computed:{
    session_id() {
      return this.$route.query.session_id
    },    
  },
  data: () => ({
  }),
  mounted() {
    this.$store.commit("set_loading",false)
    auth.currentUser.getIdToken(true).then(
    (idToken) => { 
      axios.get(
        process.env.VUE_APP_API_ROOT+"/order/cancel",{
          params:{
            session_id:this.session_id,
            idToken
          }
        })
      .then(
        //res => {}
      )
      .catch(e => {
        console.error(e);
      })
    })    
  },
  created(){
  },
  methods:{
  }
};
</script>
<style lang="scss">
/* エミュレーターの注意書きを削除 */
.firebase-emulator-warning { display: none; }
</style>
