<template>
    <div class="alert-container">
        <div class="alerts">
        <!-- typeはv-bindを忘れないように -->
            <v-alert
                v-for="(alert,key) in alerts" 
                :key= key
                :type= "alert.type"
                elevation="3"
                :dismissible= !alert.crital
            >
                <div v-html="alert.message"></div>
            </v-alert>
        </div>
    </div>
</template>

<script>


export default {
    name: 'AppAlert',
    components: {
    },
    data: function () {
        return {
        }
    },
    computed: {
        alerts() {
        return this.$store.getters.alerts;
        },
    },
    created () {
    },

    mounted() {
    },
    methods: {
    },
}
</script>

<style lang="scss" scoped>
.alert-container {
  position: relative;
  .alerts {
    position: fixed;
    left: 0;
    right: 0;
    top: 20px;
    width: 90%;
    max-width: 700px;
    margin: auto;
    z-index: 1000;
  }
}
</style>