<template>
  <v-container>
    <p class="my-5 text-center text-h3">処理中です。少々お待ちください。</p>
    <div class="text-body1">ログイン処理中です。<br>画面が変わらない場合、お手数ですが下記のボタンより再度ログインをお試しください。</div>
        <div class="my-7 text-center"><v-btn @click="$router.push('/auth')" color="primary" text>再度ログインを行う</v-btn></div>


    </v-container>
</template>

<script>
import {auth} from '../firebase'


export default {
  name: 'App',

  components: {
  },
  data: () => ({
  }),
  mounted() {
      if (auth.isSignInWithEmailLink(window.location.href)) {
      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.
      let email = window.localStorage.getItem('emailForSignIn');
      if (!email) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        email = window.prompt('確認のためもう一度メールアドレスを入力してください。');
      }
      // The client SDK will parse the code from the link for you.
      auth.signInWithEmailLink(email, window.location.href)
        .then(() => {
          // Clear email from storage.
          window.localStorage.removeItem('emailForSignIn');
          this.$router.replace('/').catch(()=>{});
        })
        .catch((error) => {
          switch(error.code){
            case "auth/invalid-action-code":
              window.alert('既に使用済み等の理由でログイン用URLが無効です。恐れ入りますが再度ログイン用URLを取得してください。')
              break;
            case "auth/expired-action-code":
              window.alert('ログイン用URLの有効期限が切れています。恐れ入りますが再度ログイン用URLを取得してください。')
              break;
            default:
              window.alert('ログインに失敗しました。恐れ入りますが再度ログイン用URLを取得してください。')
          }
          // Some error occurred, you can inspect the code: error.code
          // Common errors could be invalid email and invalid or expired OTPs.
          window.localStorage.removeItem('emailForSignIn');
          this.$router.replace('/auth').catch(()=>{});
        });
    }
  },
  computed:{
  },
  methods:{
  }
};
</script>
<style lang="scss">
/* エミュレーターの注意書きを削除 */
.firebase-emulator-warning { display: none; }
</style>
