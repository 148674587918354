<template>
  <v-container>
    <ricco-list-concert></ricco-list-concert>
    <!-- <v-row>
      <v-col>
        <form action="">
          <input type="file" id="0" @change="changePic">
        </form>
      </v-col>
    </v-row> -->
    <!-- <v-btn @click="create" color="secondary">アップ</v-btn> -->
        <!-- <v-row>
      <v-col>
        <form action="">
          <input @change="uploadPic" type="image/jpeg" id="1">
        </form>
      </v-col>
    </v-row>
        <v-row>
      <v-col>
        <form action="">
          <input @change="uploadPic" type="image/jpeg" id="2">
        </form>
      </v-col>
    </v-row>
        <v-row>
      <v-col>
        <form action="">
          <input @change="uploadPic" type="image/jpeg" id="3">
        </form>
      </v-col>
    </v-row> -->



    </v-container>

</template>

<script>
import {auth,storage,db} from '../firebase'
import RiccoListConcert from '../components/RiccoListConcert.vue'


export default {
  name: 'RC_Home',
  components: {
    RiccoListConcert,
  },

  data: () => ({
    email:"",
    title: "",
    memo: "",
    file: null,
    type: "",
    dataUrl: "",
    fileName:"",
    docId:null,
  }),
  computed:{
    tickets() {
      return this.$store.getters.tickets
    },
  },
  methods:{
    signOut() {
      auth.signOut().then(()=>{
        this.$store.dispatch('set_alert',{"type":"success","message":`ログアウトしました。<br>ご利用ありがとうございました。`})
      })
    },
    create() {
      let newMetadata = {
        cacheControl: 'public,max-age=30000',
         contentType: 'image/jpeg'
      }
      storage.ref().child(`concert/${this.fileName}`).put(this.file,newMetadata)
      .then(res=>{
        console.log("アップ",res)
      })
      .catch(()=>{})
    },
    changePic(e){
      this.file = e.target.files[0]
    },
    test_get_user(){
      db.collection("users").doc(this.docId).get().then(doc=>{
                if (doc.exists) {
                    console.log(doc.data())
                } else {
                  console.log("指定のドキュメントが存在しないです")
                }                
            }).catch(error=>{console.log(error,"エラーです")})
    },
    test_get_ticket(){
      db.collection("tickets").onSnapshot((querySnapshot) => {
        querySnapshot.forEach((doc) => {console.log(doc.data())})
      })
    },
  },
  watch:{
    file(val) {
      this.fileName = val.name;
      if (val.type == "image/jpeg") this.type = "jpeg";
      else if (val.type == "image/png") this.type = "png";
      else if (val.type == "image/gif") this.type = "gif";
      const reader = new FileReader();
      reader.readAsDataURL(this.file);
      reader.onload = (e) => {
        this.dataUrl = e.target.result;
      };
    },
  }
};
</script>
<style lang="scss">
</style>
