    <template>
    <v-container fill-height fluid>
          <v-progress-circular
            indeterminate
            color="primary"
            class="mx-auto"
            size="50"
          ></v-progress-circular>
    </v-container>
    </template>

    

<script>
export default {
    name: 'Apploading',
    created(){
    }
}
</script>

<style>

</style>

