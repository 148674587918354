<template>
<div>
    <v-app-bar
      color="white"
      light
      dense
      app
      elevation="2"
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <div class="primary--text font-weight-bold" @click="$router.push('/').catch(error=>{})">riccoチケット</div>
      <v-spacer></v-spacer>
      <v-btn class="" small v-if="(!currentUser)&&$route.path!=='/auth'" @click="$router.push('/auth')" color="primary" outlined>ログインする</v-btn>
      <v-btn class="" small v-if="currentUser&&$route.path!=='/my-ticket'" @click="$router.push('/my-ticket')" color="primary" outlined>購入チケット確認</v-btn>
      <v-btn icon :disabled="!(currentUser&&$route.path!=='/regi-check')">
        <v-badge
          color="info"
          overlap
          dot
          :value="currentUser&&regiItems.length&&$route.path!=='/regi-check'"
        >
        <v-icon
          @click="$router.push('/regi-check')"
          v-if="currentUser&&$route.path!=='/regi-check'"
          size="27"
        >mdi-cart-outline</v-icon>
      </v-badge>
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      temporary
      app
    >
      <v-list
        nav
        dense
      >
        <v-list-item-group
            v-model="group"
            color="primary"
            :value="activeList"
        >
          <v-list-item to="/" value="/" exact replace>
            <v-list-item-title>ホーム</v-list-item-title>
          </v-list-item>
          <v-list-item to="/regi-check" value="/regi-check" exact replace v-if="currentUser">
            <v-list-item-title>ショッピングカートの確認</v-list-item-title>
          </v-list-item>
          <v-list-item to="/my-ticket" value="/my-ticket" exact replace v-if="currentUser">
            <v-list-item-title>購入済みチケットの確認</v-list-item-title>
          </v-list-item>
          <v-list-item to="/customer-profile" value="/customer-profile" exact replace v-if="currentUser">
            <v-list-item-title>プロフィールの確認・変更</v-list-item-title>
          </v-list-item>
          <v-list-item to="/contact" value="/contact" exact replace>
            <v-list-item-title>お問合せ</v-list-item-title>
          </v-list-item>                 
          <div class="btn-area">
            <v-btn @click="dialog1=true" color="primary" outlined  dense v-if="is_signed_in">ログアウト</v-btn>
            <v-btn to="/auth" color="primary" outlined  dense v-if="!is_signed_in">ログイン</v-btn>
          </div>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-dialog
      v-model="dialog1"
      width="500"
    >
      <v-card>
        <v-card-title class="grey lighten-2 py-1">
         確認
        </v-card-title>
        <p class="pl-3 pt-3 text-body1">ログアウトしてよろしいですか。</p>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="signOut()"
          >
            ログアウトする
          </v-btn>
          <v-btn
            color="primary"
            outlined
            @click="dialog1=false"
          >
            キャンセル
          </v-btn>          
        </v-card-actions>
      </v-card>
    </v-dialog>

</div>
</template>
<script>
import {auth} from '../firebase'


export default {
    name: 'RiccoTopNavi',
    components: {
    },
    data: function () {
        return {
            drawer:false,
            group:null,
            dialog1:false,
        }
    },
    computed: {
        is_signed_in: function() {
            return this.$store.getters.currentUser
        },
        activeList: function(){
            return this.$route.path
        },
        regiItems(){
          return this.$store.getters.regiItems
        },
        currentUser(){
          return this.$store.getters.currentUser
        }
    },
    created () {
    },

    mounted() {
    },
    beforeDestroy () {
    },
    methods: {
        signOut () {
          this.dialog1 = false
          this.drawer = !this.drawer
          auth.signOut()
          .then(() => {
            this.$router.push('/').catch(()=>{})
            this.$store.dispatch('set_alert',{"type":"success","message":`ログアウトしました。<br>ご利用ありがとうございました。`})
          })
        },
        toHome () {
        this.drawer = !this.drawer
        this.group = 0
        this.$router.push('/').catch(()=>{})
        },    
    },
}
</script>

<style scoped>
.btn-area {
    margin: 20px 0 20px 0;
    text-align: center;
}

</style>