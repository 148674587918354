<template>
  <div>
    <section>
      <div class="container">
        <div class="columns">
          <div class="column">
              {{json}}
            {{isReadQr}}
          </div>
        </div>
      </div>
    </section>
    <section class="hero is-medium has-text-centered">
      <div class="hero-body hero-body-hp-main">
        <div class="container">
          <div class="columns">
            <div class="column">
              <v-btn
                class="button"
                @click="cameraStart()">カメラスタート</v-btn>
            </div>
            <div class="column">
              <v-btn
                class="button"
                @click="readImage()">読み取りスタート</v-btn>
            </div>
            <div class="column">
              <v-btn
                outlined
                color="primary"
                @click="cameraEnd()">カメラ終了</v-btn>
            </div>
            <div class="column">
              <v-btn
                outlined
                color="primary"
                @click="stopReadImage()">読み取り終了</v-btn>
            </div>

          </div>
          <div class="columns">
            <div class="column text-center">
              <video
                :width="width"
                :height="height"
                autoplay
                muted 
                playsinline></video>
            </div>
            <div class="column">
              <div :style="{ width: width + 'px', height: height+ 'px' }">
                <!-- canvasなしでも仮想DOMを作成して描画 -->
                <!-- <canvas
                  :width="width"
                  :height="height"
                /> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <v-row class="my-7" no-gutters>
        <v-col class="text-center">
        <v-btn @click="$router.push('/')" color="primary" outlined>ホームへ</v-btn>
        </v-col>
        <v-col class="text-center">
        <v-btn @click="$router.push('/my-concert')" color="primary" outlined>マイコンサートへ</v-btn>
        </v-col>

  </v-row>  
    </section>

  </div>
</template>

<script>
import jsQR from "jsqr";
import axios from 'axios'
import '../axios_setting.js'

export default {
  name: "RC_TicketCheck",
  data() {
    return {
      srcObject: "",
      width: 300,
      height: 400,
      json: null,
      audio: new Audio(require('../assets/read.mp3')),
      intervalId:null,
      mediaStream:null,
    };
  },
  computed: {
    isReadQr() {
      return Boolean(this.json);
    },
    user(){
      return this.$store.getters.user
    },
  },
  beforeDestroy(){
    this.stopReadImage()
    this.mediaStream.getTracks().forEach(track => track.stop())
  },
  methods: {
    async cameraStart() {
      try{
        this.mediaStream = await navigator.mediaDevices.getUserMedia({
        audio: false,
        video: {
          facingMode: { exact: "environment" },          
          width: this.width,
          height: this.height,
          frameRate: { ideal: 5, max: 15 }
        }
        });
        document.querySelector("video").srcObject = this.mediaStream;
      } catch (err){
        console.log(err.name + ': ' + err.message)
      }
    },
    cameraEnd(){
      this.mediaStream.getTracks().forEach(track => track.stop())
    },    
    readImage() {
      const video = document.querySelector("video");
      video.autoplay = true;
    video.muted = true;
    video.playsInline = true;      
      const canv = document.createElement("canvas");
      canv.height = this.height;
      canv.width = this.width;

      const context = canv.getContext("2d");

      this.intervalId = setInterval(() => {
        console.log("search .....");
        context.drawImage(video, 0, 0, this.width, this.height);
        const imageData = context.getImageData(0, 0, this.width, this.height);
        const code = jsQR(imageData.data, imageData.width, imageData.height);
        if (code) {
          console.log("Found QR code", code, code.data);
          this.json = code.data;
          axios.post(
              process.env.VUE_APP_API_ROOT+"/check-ticket",
              {
                ticketId:this.json,
                //あとで必ず直す
                 //this.user.uidへ
                 artist_uid: "ZwidSif50IdGDPTpL6Betiz6WI23",
              },
              {withCredentials: true})
          .then(
              res => {
              console.log(res.data)
              this.audio.play()
              })
          .catch(e => {
              console.error(e);
              });          
        }
      }, 3000);
    },
    stopReadImage(){
      clearInterval(this.intervalId)
    },
  }
};
</script>
