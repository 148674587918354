<template>
  <div v-if="!this.concert">申し訳ございません。<br>お探しの公演が見つかりませんでした。</div>
  <v-container class="wrapper px-5 px-sm-7" v-else>

    <v-btn transition="fade-transition" fixed bottom right class="accent2" color="accent2" light rounded id="bottom-btn" @click="pageLink()">チケットを購入する</v-btn>
  
    <v-row no-gutters>
        <v-col class="text-center concert-big-pic-wrapper">
          <img src="@/assets/2.jpg" alt="concert" class="concert-big-pic">
        </v-col>
    </v-row>

    <div class="concert-title mt-5">{{concert.name}}</div>

    <div class="concert-h1">
        公演概要
    </div>
    <div class="concert-p">
      ピアノ音楽教室ricco講師陣による演奏会です。
    </div>
    <!-- <div class="concert-h1">プロフィール</div>
    <p class="text-h4">富田 なつみ</p>
    <div class="">
      <p class="concert-p">4歳よりヤマハ音楽教室にてピアノを始める。ヤマハ専門コースに進級し、シティコンサート等出演。鎌倉音楽コンクール中学生の部、高校生の部第2位。ショパンコンクールin ASIA高校生の部アジア大会入選、大学生の部全国大会金賞 アジア大会努力賞。ペトロフ音楽コンクール第5位。かながわ音楽コンクールシニア部門 本選入選。桐朋学園大学ピアノ専攻卒業後、ヤマハ音楽教室システム講師を経て、現在ピアノ音楽教室riccoを主宰。     
      </p>
    </div>
      <p class="text-h4">松本 日向子</p>
    <div >
      <p class="concert-p">5歳よりピアノを始める。桐朋女子高等学校音楽科、桐朋学園音楽大学を経て、2016年 同大学研究科を修了。大学在学中より、横浜を拠点に毎年ジョイントコンサートを開催。数々のコンクールで入賞。ウィーン夏期音楽セミナー、霧島国際音楽祭マスタークラス受講。共に修了コンサート出演。これまで、鈴木陽子、山田冨士子、本村久子、紅林こずえ、若林顕、 チェンバロを上尾直毅、室内楽を石井志都子、久保良治、鶴園紫磯子の各氏に師事。現在、都内近郊で演奏活動を続けながら後進の指導に力を入れている。        
      </p>

    </div> -->
    <div class="concert-h1">日時</div>
    <div>
      <p class="text-h4">2024年3月30日(土)
      </p>
      <p class="concert-p">
        第1部 11:00〜<br>
        第2部 13:15〜<br>
      </p>
    </div>
    <div class="concert-h1">チケット販売期間</div>
    <div>
      <p class="text-h4">2024年3月29日まで
      </p>
    </div>
    
    <div class="concert-h1">チケット情報</div>  
    <v-card
    class="mx-auto my-6"
    max-width="400"
    outlined
    v-for="ticket in concert.tickets" :key="ticket.id"
  >
      <v-list-item three-line>
        <v-list-item-content>
          <v-list-item-title>
            {{ticket.name}}
          </v-list-item-title>
          <v-list-item-content class=" ticket-price text-h2">{{ticket.price | priceLocaleString}}円</v-list-item-content>
          <v-list-item-content v-if="ticket.desc" class="text-subtitle grey--text text--darken-2 ticket-desc">{{ticket.desc}}</v-list-item-content>
        </v-list-item-content>
      </v-list-item>
    </v-card>

    <div class="concert-h1">入場券の注意事項</div>
    <p>小学生以上の方は入場券が必ず必要です。<br>小学生未満のお子さまは、保護者の方のお膝に一緒に座る場合はチケットは不要ですが、椅子に着席する場合はチケットが必要です。<br>チケットの購入無く立ち見することはできません。</p>

    <div class="concert-h1">オンライン視聴券の視聴方法</div>
    <p>コンサートのライブ視聴用のURLを、後日メールでお送りしますので、そちらからご覧いただけます。</p>


    <div class="concert-h1" id="target-el">チケット購入</div>
    <div v-if="concert.onSale===true">
      <div v-if="currentUser==null">
        <v-row class="ticket-btn-area" justify="center" align="center">
          <v-btn large @click="toAuth" color="secondary" dark>ログインして購入する</v-btn>
        </v-row>
      </div>
      <div class="ticket-table" v-else>
        <div v-if="concert.limit_quantity<20&&concert.limit_quantity>0">
          <p>残り座席数：{{this.concert.limit_quantity}}枚</p>
          <p class="py-0 text-caption text--secondary">オンラインチケットは上記枚数に限らず購入可能です。</p>
        </div>
        <div v-if="concert.limit_quantity<1">
          <p class="text-h3"><b>本公演の会場入場チケットは完売となりました。</b></p>
          <p class="py-0">オンライン視聴チケットは引き続き購入可能です。</p>
        </div>
        <div v-if="concert.limit_quantity>0">
          <p class="py-0 text-caption text--secondary">ショッピングカートにチケットを入れた段階では、座席の確保はまだされていません。決済時に確保されます。</p>
        </div>
        <v-row v-for="item in concert.tickets" :key="item.id" justify="center" align="center" class="ticket-row" no-gutters>
          <v-col class="text-center" sm="3">{{item.name}}</v-col>
          <v-col class="text-center" sm="3">{{item.price | priceLocaleString}}円</v-col>
          <v-col class="text-center" sm="3">
            <v-select
              :items=[null,0,1,2,3,4,5,6,7,8,9,10]
              :id="item.id"
              @change="fetchQuantity(item.id,$event)"
              dense
              :disabled="!item.online&&concert.limit_quantity<1"
              single-line
              append-icon=""
              outlined
              suffix="枚"
              color="secondary"
              hide-details="true"
            ></v-select>
          </v-col>
        </v-row>
        <v-row class="py-7" justify="center" align="center" no-gutters>
          <v-col class="text-center">
          <v-btn large @click="toPayment" color="secondary" dark >カートに追加して購入手続きへ</v-btn>
          </v-col>
        </v-row>
      </div>
    </div>
    <div v-else><p>本公演はチケット販売を終了しました。</p></div>
    <v-row class="my-7" no-gutters>
        <v-col class="text-center">
        <v-btn @click="$router.push('/')" color="primary" outlined>TOPページへ</v-btn>
        </v-col>
  </v-row>  
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'RC_DetailConcert',
  async created(){
    await this.$store.dispatch('get_concert',this.$route.query.id)
    this.fixedElm = document.getElementById('bottom-btn')
    this.targetPoint = document.getElementById('target-el').getBoundingClientRect().top ;
    window.addEventListener("scroll",this.scrollHandle,{passive: true});
  },
  destroyed(){
    window.removeEventListener("scroll",this.scrollHandle,{passive: true})
  },
  components: {
  },
  data: () => ({
    fixedElm:null,
    scrollPoin:0,
    targetPoint:0,
    selectedPic:"",
    selectedTickets:[],
    loading:false,
  }),
  computed:{
    ...mapGetters([
      'concert',
      'concerts',
      'currentUser',
      'regiItems',
    ]),    

},
  methods:{
    pageLink(){
      let target = window.scrollY + document.getElementById('target-el').getBoundingClientRect().top
      scrollTo(0,target)
    },
    scrollHandle(){
        this.scrollPoint = window.scrollY + document.getElementById('bottom-btn').getBoundingClientRect().top;
        if(this.scrollPoint > this.targetPoint){ // 下スクロールの場合
          this.fixedElm.classList.add('hide');
        } else {
          this.fixedElm.classList.remove('hide')
        }
    },
    toAuth(){
      window.localStorage.setItem('redirect',this.$route.fullPath)
      this.$router.push({path:'auth'}).catch(()=>{})
    },
    toPayment(){
      this.loading =true
      let tickets = []
      let regiitem = {}
      let total_quantity_not_online = 0
      //数量を入れているかチェック
      if(this.selectedTickets.length>0&&this.selectedTickets.some(el=>el.quantity>0)){
        for(let i=0;i<this.concert.tickets.length;i++){
          let quantity
          let priceId = this.concert.tickets[i].id
          //数量配列の中から当該チケットを探す
          let quantityArrayTargetTicketIndex = this.selectedTickets.findIndex(element => element.price === priceId)
          if (quantityArrayTargetTicketIndex>-1){
            quantity = this.selectedTickets[quantityArrayTargetTicketIndex].quantity
            //オンラインチケットではないならば
            if(!this.concert.tickets[i].online){
              total_quantity_not_online += quantity
            }
          } else {
          //数量配列に該当がないなら0を入れる
            quantity = 0
          }
          tickets.push({"price":priceId,"quantity":quantity})
        }
        //在庫が足りる場合
          //レジ内の現地鑑賞チケット枚数をカウント
          //オンライン以外のチケット枚数をカウント
          let not_online_tickets_in_regi = 0

          //オンラインチェック用関数
          let checkOnline= function(concerts,priceId,concertId){
            let the_concert = concerts.find(item => item.id === concertId)
            console.log(the_concert,"ざこんさーと")
            let the_ticket =the_concert.tickets.find(item => item.id === priceId)
            console.log(the_ticket,"ざちけっと")
            return the_ticket.online
          }
          if(this.regiItems.length>0){
            for(let i=0;i<this.regiItems.length;i++){
              if(!checkOnline(this.concerts,this.regiItems[0].tickets[i].price,this.concert.id)){
                not_online_tickets_in_regi += this.regiItems[0].tickets[i].quantity
              }
            }
          }

        if(this.concert.limit_quantity >= total_quantity_not_online + not_online_tickets_in_regi){
          regiitem.tickets = tickets
          regiitem.concertId = this.concert.id
          regiitem.artist_uid = this.concert.artist_uid
          this.$store.commit('set_regiItems',regiitem)
          if(this.currentUser == null){
            this.$router.push({path:'auth'}).catch(()=>{})
            window.localStorage.setItem('redirect',"/regi-check")
          } else {
            this.$router.push('/regi-check').catch(()=>{})
          }
        //在庫が足りない場合
        } else {
          if(not_online_tickets_in_regi===0){
          this.$store.dispatch('set_alert',{"type":"error","message":`残り座席数は${this.concert.limit_quantity}です。枚数が超過しています。`})
          } else {
            this.$store.dispatch('set_alert',{"type":"error","message":`残り座席数は${this.concert.limit_quantity}です。ショッピングカート内のチケットと合わせると超過します。`})
          }
        }
      } else {
        this.$store.dispatch('set_alert',{"type":"error","message":"チケット枚数を選んでください"})
      }
    },
    fetchQuantity(id,e){
      let matchIndex = this.selectedTickets.findIndex(element=>element.price === id)
      if(matchIndex>-1){
      this.selectedTickets[matchIndex].quantity = e
      } else {
        this.selectedTickets.push({"price":id,"quantity":e})
      }

    },
  }
}
</script>

<style scoped lang="scss">
.wrapper{
  width: 100%;
  background-color: white;
  max-width: 600px;
}

img.concert-big-pic {
  width:60%;
  min-width: 200px;
  max-width: 300px;
}



img.concert-small-pic {
  width:80%;
  min-width:20px;
  max-width:300px
}

img.selected{
  border: 5px solid #635152;
}

.concert-title{
  font-size: 1.4rem;
  font-weight: bold;
}

.concert-h1{
  margin-top: 30px;
  margin-bottom: 10px;
  font-size: 1.2rem;
  border-bottom: 1px solid #ccc;
}
.concert-p{
    margin-bottom: 5px;
    line-height: 25px;
    text-align: justify;
    text-justify: inter-ideograph;
    white-space: pre-wrap;
  }

.ticket-table{
  .ticket-row{
    border-bottom: 1px solid #CCC;
    padding-top: 15px;
    padding-bottom:15px;
  }
}
.ticket-price{
  line-height: 23px;
}
.ticket-desc{
  line-height: 23px;
}

.ticket-btn-area{
  height:150px;
}

iframe{
  width: 100%;
  margin: 0 auto;
  max-width: 600px;
  aspect-ratio: 16/12; //アスペクト比（縦横比）を指定
}

.hide{
  visibility: hidden;
}

#bottom-btn{
  z-index: 200;
}

</style>
